/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type Profile_getViewer_QueryVariables = {};
export type Profile_getViewer_QueryResponse = {
    readonly viewer: {
        readonly id: string;
        readonly email: string;
        readonly name: string;
        readonly preferences: {
            readonly language: string;
        };
    } | null;
};
export type Profile_getViewer_Query = {
    readonly response: Profile_getViewer_QueryResponse;
    readonly variables: Profile_getViewer_QueryVariables;
};



/*
query Profile_getViewer_Query {
  viewer {
    id
    email
    name
    preferences {
      language
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Admin",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminPreferences",
        "kind": "LinkedField",
        "name": "preferences",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "language",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Profile_getViewer_Query",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Profile_getViewer_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "56561ac34472dcca0e2677d7fa8e908d",
    "id": null,
    "metadata": {},
    "name": "Profile_getViewer_Query",
    "operationKind": "query",
    "text": "query Profile_getViewer_Query {\n  viewer {\n    id\n    email\n    name\n    preferences {\n      language\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd54ae68b7a08bcbfe0239f05825c777d';
export default node;
