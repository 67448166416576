import React from "react";

const Users = () => {
  return (
    <div className="py-4 px-5">
      <header className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="text-muted">Users</h2>
      </header>
      <main></main>
    </div>
  );
};

export default Users;
