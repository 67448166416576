import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { commitLocalUpdate, useRelayEnvironment } from "react-relay";

import { useAuth } from "contexts/Auth";

const Logout = () => {
  const user = useAuth();
  const relayEnvironment = useRelayEnvironment();

  useEffect(() => {
    // @ts-expect-error wrong Relay types, store should be a RecordSourceSelectorProxy
    commitLocalUpdate(relayEnvironment, (store) => store.invalidateStore());
    user.setAuthToken(null);
  }, [relayEnvironment, user]);

  return (
    <div className="p-4">
      <header className="d-flex justify-content-between align-items-center">
        <h2>
          <FormattedMessage
            id="pages.Logout.title"
            defaultMessage="Logout"
            description="Title for the Logout page"
          />
        </h2>
      </header>
      <main className="mt-4"></main>
    </div>
  );
};

export default Logout;
