import React, { Suspense, useCallback, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import type { Applications_getApplications_Query } from "api/__generated__/Applications_getApplications_Query.graphql";
import type { Applications_deleteApplication_Mutation } from "api/__generated__/Applications_deleteApplication_Mutation.graphql";
import { useParams } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import {
  applicationScopeMessages,
  isSupportedApplicationScope,
} from "components/ApplicationScopeInput";
import DeleteModal from "components/DeleteModal";
import Icon from "components/Icon";
import OptionsMenu from "components/OptionsMenu";
import SectionCard from "components/SectionCard";
import Spinner from "components/Spinner";
import Result from "components/Result";
import { Link, Route } from "Navigation";
import "./Applications.scss";

const GET_APPLICATIONS_QUERY = graphql`
  query Applications_getApplications_Query($id: ID!) {
    tenant(id: $id) {
      applications {
        id
        slug
        displayName
        scope
      }
    }
  }
`;

const DELETE_APPLICATION_MUTATION = graphql`
  mutation Applications_deleteApplication_Mutation(
    $input: DeleteApplicationInput!
  ) {
    deleteApplication(input: $input) {
      application {
        id
      }
    }
  }
`;

type SelectedApplication = { id: string; slug: string; displayName: string };

type ApplicationsContentProps = {
  getApplicationsQuery: PreloadedQuery<Applications_getApplications_Query>;
};

const ApplicationsContent = ({
  getApplicationsQuery,
}: ApplicationsContentProps) => {
  const intl = useIntl();
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const [applicationToDelete, setApplicationToDelete] =
    useState<SelectedApplication | null>(null);
  const { tenantId = "" } = useParams();
  const applicationsData = usePreloadedQuery(
    GET_APPLICATIONS_QUERY,
    getApplicationsQuery
  );

  const [deleteApplication, isDeletingApplication] =
    useMutation<Applications_deleteApplication_Mutation>(
      DELETE_APPLICATION_MUTATION
    );

  const handleDeleteApplication = useCallback(
    async (applicationId: string) => {
      deleteApplication({
        variables: { input: { applicationId } },
        onCompleted(data, errors) {
          setApplicationToDelete(null);
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setErrorFeedback(errorFeedback);
          }
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.Applications.deleteErrorFeedback"
              defaultMessage="Could not delete the application, please try again."
              description="Feedback for unknown deletion error in the Applications page"
            />
          );
          setApplicationToDelete(null);
        },
        updater(store, data) {
          const applicationId = data?.deleteApplication?.application.id;
          if (applicationId) {
            const tenant = store.get(tenantId);
            const applications = tenant?.getLinkedRecords("applications");
            if (tenant && applications) {
              tenant.setLinkedRecords(
                applications.filter(
                  (application) => application.getDataID() !== applicationId
                ),
                "applications"
              );
            }
          }
        },
      });
    },
    [deleteApplication, tenantId]
  );

  if (!applicationsData.tenant) {
    return (
      <Result.NotFound
        title={
          <FormattedMessage
            id="pages.Applications.tenantNotFound.title"
            defaultMessage="Tenant not found."
            description="Page title for a tenant not found"
          />
        }
      >
        <Link route={Route.tenants}>
          <FormattedMessage
            id="pages.Applications.tenantNotFound.message"
            defaultMessage="Return to the tenant list."
            description="Page message for a tenant not found"
          />
        </Link>
      </Result.NotFound>
    );
  }

  const applications = applicationsData.tenant.applications;

  return (
    <div className="py-4 px-5">
      <header className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="text-muted">
          <FormattedMessage
            id="pages.Applications.title"
            defaultMessage="Applications"
            description="Title for the Applications page"
          />
        </h2>
      </header>
      <main>
        <Alert
          show={!!errorFeedback}
          variant="danger"
          onClose={() => setErrorFeedback(null)}
          dismissible
        >
          {errorFeedback}
        </Alert>
        {applications.length === 0 ? (
          <div className="mt-5 d-flex justify-content-center">
            <div className="rounded shadow p-5 text-center">
              <h2>
                <FormattedMessage
                  id="pages.Applications.noApplicationsTitle"
                  defaultMessage="This space is empty"
                  description="Title for missing applications message in Applications page"
                />
              </h2>
              <p className="text-muted">
                <FormattedMessage
                  id="pages.Applications.noApplicationsSubtitle"
                  defaultMessage="You haven't created any applications yet."
                  description="Subtitle for missing applications message in Applications page"
                />
              </p>
              <p className="mt-5">
                <Link
                  route={Route.applicationsNew}
                  params={{ tenantId }}
                  className="btn btn-primary"
                >
                  <FormattedMessage
                    id="pages.Applications.noApplicationsButton"
                    defaultMessage="Create new application"
                    description="Title for the link to create the first application in Applications page"
                  />
                </Link>
              </p>
            </div>
          </div>
        ) : (
          <div className="applications-list">
            <Link
              route={Route.applicationsNew}
              params={{ tenantId }}
              className="rounded shadow p-3 mb-4 d-flex justify-content-center align-items-center"
            >
              <Icon icon="plus" className="me-2" />
              <strong>
                <FormattedMessage
                  id="pages.Applications.createNewApplication"
                  defaultMessage="Create new application"
                  description="Title for the card to create a new application in Applications page"
                />
              </strong>
            </Link>
            {applications.map((application) => (
              <SectionCard
                key={application.id}
                title={
                  <Link
                    route={Route.applicationEdit}
                    params={{ tenantId, applicationId: application.id }}
                  >
                    {application.displayName}
                  </Link>
                }
                menu={
                  <OptionsMenu alignEnd>
                    <OptionsMenu.Item
                      className="text-danger"
                      onClick={() =>
                        setApplicationToDelete({
                          id: application.id,
                          slug: application.slug,
                          displayName: application.displayName,
                        })
                      }
                    >
                      <FormattedMessage
                        id="pages.Applications.applicationDeleteButton"
                        defaultMessage="Delete"
                        description="Button to delete an application in the Applications page"
                      />
                    </OptionsMenu.Item>
                  </OptionsMenu>
                }
              >
                <div className="mb-2">
                  <table>
                    <tbody>
                      <tr>
                        <td className="text-muted">
                          <FormattedMessage
                            id="pages.Applications.applicationInfo.slugLabel"
                            defaultMessage="Slug"
                            description="Label for the slug property of an app in Applications page"
                          />
                        </td>
                        <td className="ps-2">{application.slug}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">
                          <FormattedMessage
                            id="pages.Applications.applicationInfo.scopeLabel"
                            defaultMessage="Scope"
                            description="Label for the scope property of an app in Applications page"
                          />
                        </td>
                        <td className="ps-2">
                          {isSupportedApplicationScope(application.scope)
                            ? intl.formatMessage(
                                applicationScopeMessages[application.scope]
                              )
                            : application.scope}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </SectionCard>
            ))}
          </div>
        )}
        {applicationToDelete && (
          <DeleteModal
            confirmText={applicationToDelete.slug}
            onCancel={() => setApplicationToDelete(null)}
            onConfirm={() => handleDeleteApplication(applicationToDelete.id)}
            isDeleting={isDeletingApplication}
            title={
              <FormattedMessage
                id="pages.Applications.deleteModal.title"
                defaultMessage="Delete Application"
                description="Title for the confirmation modal to delete an application"
              />
            }
          >
            <p>
              <FormattedMessage
                id="pages.Applications.deleteModal.description"
                defaultMessage="This action cannot be undone. This will permanently delete the application <bold>{application}</bold>."
                description="Description for the confirmation modal to delete an application"
                values={{
                  application: applicationToDelete.displayName,
                  bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
                }}
              />
            </p>
          </DeleteModal>
        )}
      </main>
    </div>
  );
};

const Applications = () => {
  const { tenantId = "" } = useParams();
  const [getApplicationsQuery, getApplications] =
    useQueryLoader<Applications_getApplications_Query>(GET_APPLICATIONS_QUERY);

  useEffect(
    () => getApplications({ id: tenantId }),
    [getApplications, tenantId]
  );

  return (
    <Suspense
      fallback={
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={(props) => (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <p>
              <FormattedMessage
                id="pages.Applications.Applications.feedback"
                defaultMessage="The page couldn't load."
                description="Feedback message on a loading error for the Applications page"
              />
            </p>
            <Button onClick={props.resetErrorBoundary}>
              <FormattedMessage
                id="pages.Applications.loadingError.retryButton"
                defaultMessage="Try again"
                description="Retry button on loading error for the Applications page"
              />
            </Button>
          </div>
        )}
        onReset={() => getApplications({ id: tenantId })}
      >
        {getApplicationsQuery && (
          <ApplicationsContent getApplicationsQuery={getApplicationsQuery} />
        )}
      </ErrorBoundary>
    </Suspense>
  );
};

export default Applications;
