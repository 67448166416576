import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

import Icon from "components/Icon";
import "./InterfacesTable.scss";

type Interface = {
  name: string;
  majorVersion: number;
  minorVersion: number;
};

type Props = {
  className?: string;
  interfaces: Interface[];
  onChange: (interfaces: Interface[]) => void;
};

const defaultInterface: Interface = {
  name: "",
  majorVersion: 0,
  minorVersion: 0,
};

const InterfacesTable = ({ className, interfaces, onChange }: Props) => {
  const [draftInterface, setDraftInterface] =
    useState<Interface>(defaultInterface);

  const handleInterfaceChange = useCallback(
    (index: number, iface: Interface) => {
      const newInterfaces = Object.assign([], interfaces, { [index]: iface });
      onChange(newInterfaces);
    },
    [onChange, interfaces]
  );

  const handleInterfaceDelete = useCallback(
    (index: number) => {
      const newInterfaces = interfaces.filter((_, idx) => idx !== index);
      onChange(newInterfaces);
    },
    [onChange, interfaces]
  );

  const handleInterfaceAdd = useCallback(() => {
    const newInterfaces = interfaces.concat(draftInterface);
    setDraftInterface(defaultInterface);
    onChange(newInterfaces);
  }, [onChange, draftInterface, interfaces]);

  return (
    <Table responsive className="border interfaces-table">
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="components.InterfacesTable.nameLabel"
              defaultMessage="Interface name"
              description="The label for the Astarte interface name"
            />
          </th>
          <th>
            <FormattedMessage
              id="components.InterfacesTable.majorVersionLabel"
              defaultMessage="Major"
              description="The label for the Astarte interface major version"
            />
          </th>
          <th>
            <FormattedMessage
              id="components.InterfacesTable.minorVersionLabel"
              defaultMessage="Minor"
              description="The label for the Astarte interface minor version"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {interfaces.map((iface, index) => (
          <tr key={index}>
            <td>
              <Form.Control
                value={iface.name}
                onChange={(event) =>
                  handleInterfaceChange(index, {
                    ...iface,
                    name: event.target.value,
                  })
                }
                required
              />
            </td>
            <td>
              <Form.Control
                type="number"
                min={0}
                value={iface.majorVersion}
                onChange={(event) =>
                  handleInterfaceChange(index, {
                    ...iface,
                    majorVersion: Number.parseInt(event.target.value),
                  })
                }
                required
              />
            </td>
            <td>
              <Form.Control
                type="number"
                min={0}
                value={iface.minorVersion}
                onChange={(event) =>
                  handleInterfaceChange(index, {
                    ...iface,
                    minorVersion: Number.parseInt(event.target.value),
                  })
                }
                required
              />
            </td>
            <td>
              <Button
                variant="danger"
                onClick={() => handleInterfaceDelete(index)}
              >
                <Icon icon="delete" />
              </Button>
            </td>
          </tr>
        ))}
        <tr className="bg-light">
          <td data-name="name">
            <Form.Control
              value={draftInterface.name}
              onChange={(event) =>
                setDraftInterface({
                  ...draftInterface,
                  name: event.target.value,
                })
              }
            />
          </td>
          <td data-name="major">
            <Form.Control
              type="number"
              min={0}
              value={draftInterface.majorVersion}
              onChange={(event) =>
                setDraftInterface({
                  ...draftInterface,
                  majorVersion: Number.parseInt(event.target.value),
                })
              }
            />
          </td>
          <td data-name="minor">
            <Form.Control
              type="number"
              min={0}
              value={draftInterface.minorVersion}
              onChange={(event) =>
                setDraftInterface({
                  ...draftInterface,
                  minorVersion: Number.parseInt(event.target.value),
                })
              }
            />
          </td>
          <td>
            <Button variant="secondary" onClick={handleInterfaceAdd}>
              <Icon icon="plus" />
            </Button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export type { Interface };

export default InterfacesTable;
