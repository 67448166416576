import "./Code.scss";

type Props = {
  children?: React.ReactNode;
  className?: string;
  cli?: boolean;
  id?: string;
};

const Code = ({ children, className = "", cli = false, id }: Props) => {
  const codeClassName = [className, "p-2 border rounded bg-light", cli && "cli"]
    .filter((className) => !!className)
    .join(" ");

  const codeBlocks = Array.isArray(children) ? children : [children];

  return (
    <div id={id} className={codeClassName}>
      {codeBlocks.map((codeBlock, index) => (
        <div key={index} className="p-1">
          <code className="text-dark">{codeBlock}</code>
        </div>
      ))}
    </div>
  );
};

export default Code;
