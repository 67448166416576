/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type Tenant_getTenant_QueryVariables = {
    id: string;
};
export type Tenant_getTenant_QueryResponse = {
    readonly tenant: {
        readonly id: string;
        readonly name: string;
        readonly slug: string;
        readonly frontendDomain: string;
        readonly realm: {
            readonly name: string;
            readonly privateKey: string;
            readonly cluster: {
                readonly name: string;
                readonly provider: string;
                readonly zone: string;
                readonly baseApiUrl: string;
                readonly dashboardUrl: string;
            };
        };
        readonly design: {
            readonly logo: string | null;
            readonly theme: string | null;
        };
        readonly authConfig: {
            readonly recaptchaSecretKey: string | null;
            readonly recaptchaSiteKey: string | null;
        };
    } | null;
};
export type Tenant_getTenant_Query = {
    readonly response: Tenant_getTenant_QueryResponse;
    readonly variables: Tenant_getTenant_QueryVariables;
};



/*
query Tenant_getTenant_Query(
  $id: ID!
) {
  tenant(id: $id) {
    id
    name
    slug
    frontendDomain
    realm {
      name
      privateKey
      cluster {
        name
        provider
        zone
        baseApiUrl
        dashboardUrl
        id
      }
      id
    }
    design {
      logo
      theme
    }
    authConfig {
      recaptchaSecretKey
      recaptchaSiteKey
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "frontendDomain",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "privateKey",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zone",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baseApiUrl",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dashboardUrl",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "DesignSystem",
  "kind": "LinkedField",
  "name": "design",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theme",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "TenantAuthConfig",
  "kind": "LinkedField",
  "name": "authConfig",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recaptchaSecretKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recaptchaSiteKey",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Tenant_getTenant_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Realm",
            "kind": "LinkedField",
            "name": "realm",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Cluster",
                "kind": "LinkedField",
                "name": "cluster",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Tenant_getTenant_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Realm",
            "kind": "LinkedField",
            "name": "realm",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Cluster",
                "kind": "LinkedField",
                "name": "cluster",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "21a48e1a70f3c43161c68498b901b0ca",
    "id": null,
    "metadata": {},
    "name": "Tenant_getTenant_Query",
    "operationKind": "query",
    "text": "query Tenant_getTenant_Query(\n  $id: ID!\n) {\n  tenant(id: $id) {\n    id\n    name\n    slug\n    frontendDomain\n    realm {\n      name\n      privateKey\n      cluster {\n        name\n        provider\n        zone\n        baseApiUrl\n        dashboardUrl\n        id\n      }\n      id\n    }\n    design {\n      logo\n      theme\n    }\n    authConfig {\n      recaptchaSecretKey\n      recaptchaSiteKey\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '75d0b981b7f57f7f06c9bf47a28297ab';
export default node;
