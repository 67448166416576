import React, { Suspense, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import type { Topbar_getTenants_Query } from "api/__generated__/Topbar_getTenants_Query.graphql";
import type { Topbar_getViewer_Query } from "api/__generated__/Topbar_getViewer_Query.graphql";
import Navbar from "react-bootstrap/Navbar";

import { Link, Route, useNavigate } from "Navigation";
import Dropdown from "components/Dropdown";
import Icon from "components/Icon";
import Spinner from "components/Spinner";
import { useTenant } from "contexts/Tenant";
import "./Topbar.scss";

import * as images from "assets/images";

const GET_VIEWER_QUERY = graphql`
  query Topbar_getViewer_Query {
    viewer {
      name
    }
  }
`;

type NameDisplayProps = {
  queryReference: PreloadedQuery<Topbar_getViewer_Query>;
};

const NameDisplay = ({ queryReference }: NameDisplayProps) => {
  const data = usePreloadedQuery<Topbar_getViewer_Query>(
    GET_VIEWER_QUERY,
    queryReference
  );
  return <span>{data.viewer?.name}</span>;
};

const GET_TENANTS_QUERY = graphql`
  query Topbar_getTenants_Query {
    tenants {
      id
      name
    }
  }
`;

type TenantSelectProps = {
  className?: string;
  getTenantsQuery: PreloadedQuery<Topbar_getTenants_Query>;
};

const TenantSelect = ({ className, getTenantsQuery }: TenantSelectProps) => {
  const { tenantId } = useTenant();
  const navigate = useNavigate();
  const tenantsData = usePreloadedQuery<Topbar_getTenants_Query>(
    GET_TENANTS_QUERY,
    getTenantsQuery
  );

  const tenants = tenantsData.tenants;
  const tenant = tenants.find((t) => t.id === tenantId);

  if (tenants.length === 0) {
    return null;
  }

  return (
    <Dropdown
      alignEnd
      className={className}
      toggle={
        <div className="btn">
          {tenant ? (
            tenant.name
          ) : (
            <FormattedMessage
              id="components.Topbar.tenantMenu.selectTenantLabel"
              defaultMessage="Select tenant"
              description="Label for the prompt to select a tenant in the tenant menu"
            />
          )}
          <Icon icon="caretDown" className="ms-2" />
        </div>
      }
    >
      {tenants.map((tenant) => (
        <Dropdown.Item
          key={tenant.id}
          onClick={() =>
            navigate({
              route: Route.tenantsEdit,
              params: { tenantId: tenant.id },
            })
          }
        >
          {tenant.name}
        </Dropdown.Item>
      ))}
      <Dropdown.Divider />
      <Dropdown.Item as={Link} route={Route.tenants}>
        <FormattedMessage
          id="components.Topbar.tenantMenu.tenantsLinkLabel"
          defaultMessage="Main Dashboard"
          description="Label for the link to the Tenants dashboard in the tenant menu"
        />
      </Dropdown.Item>
    </Dropdown>
  );
};

type TenantMenuProps = {
  className?: string;
};

const TenantMenu = ({ className }: TenantMenuProps) => {
  const [getTenantsQuery, getTenants] =
    useQueryLoader<Topbar_getTenants_Query>(GET_TENANTS_QUERY);

  useEffect(() => getTenants({}), [getTenants]);

  return (
    <Suspense fallback={null}>
      {getTenantsQuery && (
        <TenantSelect getTenantsQuery={getTenantsQuery} className={className} />
      )}
    </Suspense>
  );
};

type UserMenuProps = {
  className?: string;
};

const UserMenu = ({ className }: UserMenuProps) => {
  const [getViewerQuery, getViewer] =
    useQueryLoader<Topbar_getViewer_Query>(GET_VIEWER_QUERY);

  useEffect(() => getViewer({}), [getViewer]);

  return (
    <Dropdown
      alignEnd
      className={className}
      toggle={
        <div className="btn">
          <Icon icon="profile" className="me-2" />
          <Suspense fallback={<Spinner />}>
            {getViewerQuery && <NameDisplay queryReference={getViewerQuery} />}
          </Suspense>
          <Icon icon="caretDown" className="ms-2" />
        </div>
      }
    >
      <Dropdown.Item as={Link} route={Route.profile}>
        <FormattedMessage
          id="components.Topbar.userMenu.profileLabel"
          defaultMessage="Profile"
          description="Label for the Profile item of the user menu"
        />
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item as={Link} route={Route.logout}>
        <FormattedMessage
          id="components.Topbar.userMenu.logoutLabel"
          defaultMessage="Logout"
          description="Label for the Logout item of the user menu"
        />
      </Dropdown.Item>
    </Dropdown>
  );
};

const Topbar = () => (
  <div className="pb-3">
    <Navbar className="Topbar navbar navbar-light shadow px-2">
      <Navbar.Brand className="h-100 py-2 px-4">
        <img alt="Logo" src={images.brand} className="h-100" />
      </Navbar.Brand>
      <TenantMenu />
      <UserMenu className="ms-auto" />
    </Navbar>
  </div>
);

export default Topbar;
