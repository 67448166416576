/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ApplicationScope = "APPLIANCE" | "GLOBAL" | "%future added value";
export type Applications_getApplications_QueryVariables = {
    id: string;
};
export type Applications_getApplications_QueryResponse = {
    readonly tenant: {
        readonly applications: ReadonlyArray<{
            readonly id: string;
            readonly slug: string;
            readonly displayName: string;
            readonly scope: ApplicationScope;
        }>;
    } | null;
};
export type Applications_getApplications_Query = {
    readonly response: Applications_getApplications_QueryResponse;
    readonly variables: Applications_getApplications_QueryVariables;
};



/*
query Applications_getApplications_Query(
  $id: ID!
) {
  tenant(id: $id) {
    applications {
      id
      slug
      displayName
      scope
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Application",
  "kind": "LinkedField",
  "name": "applications",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scope",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Applications_getApplications_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Applications_getApplications_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f6b94b57fc49326f5d109c53e8788734",
    "id": null,
    "metadata": {},
    "name": "Applications_getApplications_Query",
    "operationKind": "query",
    "text": "query Applications_getApplications_Query(\n  $id: ID!\n) {\n  tenant(id: $id) {\n    applications {\n      id\n      slug\n      displayName\n      scope\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '620aa0944786219fcd4a61aaf939a363';
export default node;
