import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as RouterProvider } from "react-router-dom";
import { RelayEnvironmentProvider } from "react-relay/hooks";

import { relayEnvironment } from "api";
import AuthProvider from "contexts/Auth";
import ConsoleConfigProvider from "contexts/ConsoleConfig";
import I18nProvider from "i18n";
import "./index.scss";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <RelayEnvironmentProvider environment={relayEnvironment}>
      <ConsoleConfigProvider>
        <AuthProvider>
          <RouterProvider>
            <I18nProvider>
              <App />
            </I18nProvider>
          </RouterProvider>
        </AuthProvider>
      </ConsoleConfigProvider>
    </RelayEnvironmentProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
