import React, { createContext, useContext, useEffect, useState } from "react";

import { fetchGraphQL } from "api";
import Spinner from "components/Spinner";

const consoleConfigQuery = `
  query Console_getConsoleConfig_Query {
    authConfig {
      recaptchaSiteKey
    }
  }
`;

type AuthConfig = {
  recaptchaSiteKey: string | null;
};

type ConsoleConfig = AuthConfig;

const fallbackConfig: ConsoleConfig = {
  recaptchaSiteKey: null,
};

const ConsoleConfigContext = createContext<ConsoleConfig | null>(
  fallbackConfig
);

type ConsoleConfigProviderProps = {
  children: React.ReactNode;
};

const ConsoleConfigProvider = ({ children }: ConsoleConfigProviderProps) => {
  const [consoleConfig, setConsoleConfig] = useState<ConsoleConfig | null>(
    null
  );

  useEffect(() => {
    fetchGraphQL(consoleConfigQuery, {})
      .then((response) => {
        setConsoleConfig(response.data.authConfig);
      })
      .catch(() => {
        setConsoleConfig(fallbackConfig);
      });
  }, []);

  if (!consoleConfig) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <ConsoleConfigContext.Provider value={consoleConfig}>
      {children}
    </ConsoleConfigContext.Provider>
  );
};

const useConsoleConfig = (): ConsoleConfig => {
  const contextValue = useContext(ConsoleConfigContext);
  if (contextValue == null) {
    throw new Error("ConsoleConfigContext has not been Provided");
  }
  return contextValue;
};

export { useConsoleConfig };

export default ConsoleConfigProvider;
