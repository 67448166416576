import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";

import type { Profile_getViewer_Query } from "api/__generated__/Profile_getViewer_Query.graphql";
import type { Profile_updateAdminPreferences_Mutation } from "api/__generated__/Profile_updateAdminPreferences_Mutation.graphql";
import Icon from "components/Icon";
import Result from "components/Result";
import SectionCard from "components/SectionCard";
import Spinner from "components/Spinner";
import { availableLanguages } from "i18n";
import { Link, Route, useNavigate } from "Navigation";

const GET_VIEWER_QUERY = graphql`
  query Profile_getViewer_Query {
    viewer {
      id
      email
      name
      preferences {
        language
      }
    }
  }
`;

const UPDATE_ADMIN_PREFERENCES_MUTATION = graphql`
  mutation Profile_updateAdminPreferences_Mutation(
    $input: UpdateAdminPreferencesInput!
  ) {
    updateAdminPreferences(input: $input) {
      admin {
        preferences {
          language
        }
      }
    }
  }
`;

interface ProfileContentProps {
  getViewerQuery: PreloadedQuery<Profile_getViewer_Query>;
}

const ProfileContent = ({ getViewerQuery }: ProfileContentProps) => {
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();
  const intl = useIntl();

  const viewerData = usePreloadedQuery(GET_VIEWER_QUERY, getViewerQuery);

  const [updateAdminPreferences] =
    useMutation<Profile_updateAdminPreferences_Mutation>(
      UPDATE_ADMIN_PREFERENCES_MUTATION
    );

  const viewer = useMemo(
    () => viewerData.viewer && { ...viewerData.viewer },
    [viewerData.viewer]
  );

  const handleChangePreferences: React.ChangeEventHandler<HTMLSelectElement> =
    useCallback(
      (event) => {
        const target = event.target;
        if (target.id !== "language") {
          return;
        }
        const preferences = { language: target.value };
        if (preferences.language !== viewer?.preferences.language) {
          updateAdminPreferences({
            variables: {
              input: { preferences },
            },
            onCompleted(data, errors) {
              if (errors) {
                const errorFeedback = errors
                  .map((error) => error.message)
                  .join(". \n");
                setErrorFeedback(errorFeedback);
              }
            },
            onError(error) {
              setErrorFeedback(
                <FormattedMessage
                  id="pages.Profile.loadingErrorFeedback"
                  defaultMessage="Could not update the profile, please try again."
                  description="Feedback for unknown loading error in the Profile page"
                />
              );
            },
            optimisticResponse: {
              updateAdminPreferences: {
                admin: {
                  id: viewer?.id,
                  preferences: {
                    language: preferences.language,
                  },
                },
              },
            },
          });
        }
      },
      [updateAdminPreferences, viewer?.preferences.language, viewer?.id]
    );

  if (!viewer) {
    return (
      <Result.NotFound
        title={
          <FormattedMessage
            id="pages.Profile.viewerNotFound.title"
            defaultMessage="Profile not found."
            description="Page title for a viewer profile not found"
          />
        }
      >
        <Link route={Route.tenants}>
          <FormattedMessage
            id="pages.Profile.viewerNotFound.message"
            defaultMessage="Return to the tenant list."
            description="Page message for a viewer profile not found"
          />
        </Link>
      </Result.NotFound>
    );
  }

  return (
    <div className="py-4 px-5">
      <header className="d-flex align-items-center mb-3">
        <Button
          variant="link"
          className="d-flex align-items-baseline text-muted"
          onClick={() => navigate(-1)}
        >
          <Icon icon="caretLeft" />
          <h4 className="ms-2">
            <FormattedMessage
              id="pages.Profile.backButton"
              defaultMessage="Back"
              description="Title for Back button in Profile page"
            />
          </h4>
        </Button>
        <h2 className="flex-grow-1 text-center text-muted me-5">
          <FormattedMessage
            id="pages.Profile.title"
            defaultMessage="Profile"
            description="Title for the Profile page"
          />
        </h2>
      </header>
      <main>
        <Alert
          show={!!errorFeedback}
          variant="danger"
          onClose={() => setErrorFeedback(null)}
          dismissible
        >
          {errorFeedback}
        </Alert>
        <Row className="justify-content-center">
          <Col lg={8}>
            <SectionCard
              title={
                <FormattedMessage
                  id="pages.Profile.infoSection.title"
                  defaultMessage="Info"
                  description="Title for the Info section in the Profile page"
                />
              }
            >
              <Form noValidate>
                <Stack gap={3}>
                  <Form.Group as={Row} controlId="name">
                    <Form.Label column sm="3">
                      <FormattedMessage
                        id="pages.Profile.infoSection.nameLabel"
                        defaultMessage="Name"
                        description="Label for the name field in the Profile page"
                      />
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={viewer.name} required disabled />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="email">
                    <Form.Label column sm="3">
                      <FormattedMessage
                        id="pages.Profile.infoSection.emailLabel"
                        defaultMessage="Email"
                        description="Label for the email field in the Profile page"
                      />
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={viewer.email} required disabled />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="language">
                    <Form.Label column sm="3">
                      <FormattedMessage
                        id="pages.Profile.infoSection.languageLabel"
                        defaultMessage="Language"
                        description="Label for the language field in the Profile page"
                      />
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        name="language"
                        value={viewer.preferences.language}
                        onChange={handleChangePreferences}
                        required
                      >
                        <option value="" disabled>
                          {intl.formatMessage({
                            id: "pages.Profile.infoSection.selectLanguagePrompt",
                            defaultMessage: "Select language",
                            description:
                              "Prompt to select the language in the Profile page",
                          })}
                        </option>
                        {Object.entries(availableLanguages).map(
                          ([language, languageMeta]) => (
                            <option key={language} value={language}>
                              {languageMeta.nativeName}
                            </option>
                          )
                        )}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Stack>
              </Form>
            </SectionCard>
          </Col>
        </Row>
      </main>
    </div>
  );
};

const Profile = () => {
  const [getViewerQuery, getViewer] =
    useQueryLoader<Profile_getViewer_Query>(GET_VIEWER_QUERY);

  useEffect(() => getViewer({}), [getViewer]);

  return (
    <Suspense
      fallback={
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={(props) => (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <p>
              <FormattedMessage
                id="pages.Profile.loadingError.feedback"
                defaultMessage="The page couldn't load."
                description="Feedback message on a loading error for the Profile page"
              />
            </p>
            <Button onClick={props.resetErrorBoundary}>
              <FormattedMessage
                id="pages.Profile.loadingError.retryButton"
                defaultMessage="Try again"
                description="Retry button on loading error for the Profile page"
              />
            </Button>
          </div>
        )}
        onReset={() => getViewer({})}
      >
        {getViewerQuery && <ProfileContent getViewerQuery={getViewerQuery} />}
      </ErrorBoundary>
    </Suspense>
  );
};

export default Profile;
