import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { fetchGraphQL } from "api";
import Spinner from "components/Spinner";

const viewerQuery = `
  query Auth_getViewer_Query {
    viewer {
      __typename
    }
  }
`;

type AuthContextValue = {
  isAuthenticated: boolean;
  setAuthToken: (token: string | null) => void;
};

const AuthContext = createContext<AuthContextValue | null>(null);

const loadAuthToken = (): string | null => {
  return localStorage.getItem("authToken") || null;
};

const saveAuthToken = (token: string | null) => {
  if (token) {
    localStorage.setItem("authToken", token);
  } else {
    localStorage.removeItem("authToken");
  }
};

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authToken, setAuthToken] = useState(loadAuthToken());
  const [isValidatingToken, setIsValidatingToken] = useState(!!authToken);

  const handleSetToken = useCallback((newAuthToken: string | null) => {
    saveAuthToken(newAuthToken);
    setAuthToken(newAuthToken);
  }, []);

  const isAuthenticated = useMemo(
    () => !!authToken && !isValidatingToken,
    [authToken, isValidatingToken]
  );

  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      setAuthToken: handleSetToken,
    }),
    [isAuthenticated, handleSetToken]
  );

  useEffect(() => {
    if (isValidatingToken) {
      fetchGraphQL(viewerQuery, {})
        .then((response) => {
          if (authToken && response.errors) {
            handleSetToken(null);
          }
        })
        .catch(() => {
          handleSetToken(null);
        })
        .finally(() => {
          setIsValidatingToken(false);
        });
    }
  }, [isValidatingToken, authToken, handleSetToken]);

  if (isValidatingToken) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    );
  }
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

const useAuth = (): AuthContextValue => {
  const contextValue = useContext(AuthContext);
  if (contextValue == null) {
    throw new Error("AuthContext has not been Provided");
  }
  return contextValue;
};

export { loadAuthToken, useAuth };

export default AuthProvider;
