import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import type {
  Application_getApplication_Query,
  ApplicationScope,
} from "api/__generated__/Application_getApplication_Query.graphql";
import type {
  Application_updateApplication_Mutation,
  ApplicationInput,
} from "api/__generated__/Application_updateApplication_Mutation.graphql";
import type { Application_deleteApplication_Mutation } from "api/__generated__/Application_deleteApplication_Mutation.graphql";
import { useParams } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import _ from "lodash";

import ApplicationScopeInput, {
  isSupportedApplicationScope,
} from "components/ApplicationScopeInput";
import DeleteModal from "components/DeleteModal";
import InterfacesTable, { Interface } from "components/InterfacesTable";
import OptionsMenu from "components/OptionsMenu";
import Spinner from "components/Spinner";
import Result from "components/Result";
import { Link, Route, useNavigate } from "Navigation";

const GET_APPLICATION_QUERY = graphql`
  query Application_getApplication_Query($id: ID!) {
    application(id: $id) {
      protocol
      slug
      displayName
      description
      sourceUrl
      scope
      requiredInterfaces {
        name
        majorVersion
        minorVersion
      }
    }
  }
`;

const UPDATE_APPLICATION_MUTATION = graphql`
  mutation Application_updateApplication_Mutation(
    $input: UpdateApplicationInput!
  ) {
    updateApplication(input: $input) {
      application {
        id
        protocol
        slug
        displayName
        description
        sourceUrl
        scope
        requiredInterfaces {
          name
          majorVersion
          minorVersion
        }
      }
    }
  }
`;

const DELETE_APPLICATION_MUTATION = graphql`
  mutation Application_deleteApplication_Mutation(
    $input: DeleteApplicationInput!
  ) {
    deleteApplication(input: $input) {
      application {
        id
      }
    }
  }
`;

interface FormData {
  protocol: string;
  slug: string;
  displayName: string;
  description: string;
  sourceUrl: string;
  scope: ApplicationScope;
  requiredInterfaces: Interface[];
}

const initialFormData: FormData = {
  protocol: "",
  slug: "",
  displayName: "",
  description: "",
  sourceUrl: "",
  scope: "APPLIANCE",
  requiredInterfaces: [],
};

type ApplicationContentProps = {
  getApplicationQuery: PreloadedQuery<Application_getApplication_Query>;
};

const ApplicationContent = ({
  getApplicationQuery,
}: ApplicationContentProps) => {
  const { applicationId = "", tenantId = "" } = useParams();
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [validated, setValidated] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const intl = useIntl();
  const navigate = useNavigate();

  const applicationData = usePreloadedQuery(
    GET_APPLICATION_QUERY,
    getApplicationQuery
  );

  const app = useMemo(
    () =>
      applicationData.application && {
        ...applicationData.application,
        description: applicationData.application.description || "",
        requiredInterfaces: applicationData.application.requiredInterfaces && [
          ...applicationData.application.requiredInterfaces,
        ],
      },
    [applicationData.application]
  );

  const [updateApplication, isUpdatingApplication] =
    useMutation<Application_updateApplication_Mutation>(
      UPDATE_APPLICATION_MUTATION
    );

  const [deleteApplication, isDeletingApplication] =
    useMutation<Application_deleteApplication_Mutation>(
      DELETE_APPLICATION_MUTATION
    );

  const handleInputChange: React.ChangeEventHandler<
    HTMLInputElement & HTMLSelectElement
  > = useCallback((event) => {
    const target = event.target;
    const value = target.value;
    const field = target.id;
    setFormData((data) => ({ ...data, [field]: value }));
  }, []);

  const handleInterfacesChange = useCallback((interfaces: Interface[]) => {
    setFormData((data) => ({ ...data, requiredInterfaces: interfaces }));
  }, []);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        return setValidated(true);
      }
      const application: ApplicationInput = {
        ...formData,
        scope: formData.scope as ApplicationInput["scope"],
      };
      updateApplication({
        variables: { input: { applicationId, application } },
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setErrorFeedback(errorFeedback);
          }
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.Application.updateErrorFeedback"
              defaultMessage="Could not update the application, please try again."
              description="Feedback for unknown update error in the Application page"
            />
          );
        },
      });
    },
    [updateApplication, formData, applicationId]
  );

  const handleDeleteApplication = useCallback(async () => {
    deleteApplication({
      variables: { input: { applicationId } },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setShowDeleteModal(false);
          return setErrorFeedback(errorFeedback);
        }
        navigate({ route: Route.applications, params: { tenantId } });
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.Application.deleteErrorFeedback"
            defaultMessage="Could not delete the application, please try again."
            description="Feedback for unknown deletion error in the Application page"
          />
        );
        setShowDeleteModal(false);
      },
      updater(store, data) {
        const applicationId = data?.deleteApplication?.application.id;
        if (applicationId) {
          const tenant = store.get(tenantId);
          const applications = tenant?.getLinkedRecords("applications");
          if (tenant && applications) {
            tenant.setLinkedRecords(
              applications.filter(
                (application) => application.getDataID() !== applicationId
              ),
              "applications"
            );
          }
        }
      },
    });
  }, [deleteApplication, navigate, applicationId, tenantId]);

  useEffect(() => {
    if (app) {
      setFormData(app);
    }
  }, [app]);

  if (!app) {
    return (
      <Result.NotFound
        title={
          <FormattedMessage
            id="pages.Application.applicationNotFound.title"
            defaultMessage="Application not found."
            description="Page title for an application not found"
          />
        }
      >
        <Link route={Route.applications} params={{ tenantId }}>
          <FormattedMessage
            id="pages.Application.applicationNotFound.message"
            defaultMessage="Return to the application list."
            description="Page message for a application not found"
          />
        </Link>
      </Result.NotFound>
    );
  }

  const canUpdateApplication =
    !_.isEqual(formData, app) && !isUpdatingApplication;

  return (
    <div className="py-4 px-5">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Stack gap={3}>
          <header className="d-flex justify-content-between align-items-center">
            <h2 className="text-muted">{app.displayName}</h2>
            <div className="d-flex">
              <Button
                variant="primary"
                type="submit"
                disabled={!canUpdateApplication}
              >
                {isUpdatingApplication && (
                  <Spinner size="sm" className="me-2" />
                )}
                <FormattedMessage
                  id="pages.Application.form.updateButton"
                  defaultMessage="Update"
                  description="Title for the button to update the application in the Application page"
                />
              </Button>
              <OptionsMenu alignEnd>
                <OptionsMenu.Item
                  className="text-danger"
                  onClick={() => setShowDeleteModal(true)}
                >
                  <FormattedMessage
                    id="pages.Application.deleteButton"
                    defaultMessage="Delete"
                    description="Button to delete the application in the Application page"
                  />
                </OptionsMenu.Item>
              </OptionsMenu>
            </div>
          </header>
          <Alert
            show={!!errorFeedback}
            variant="danger"
            onClose={() => setErrorFeedback(null)}
            dismissible
          >
            {errorFeedback}
          </Alert>
          <Form.Group as={Row} controlId="protocol">
            <Form.Label column sm="2">
              <FormattedMessage
                id="pages.Application.protocolLabel"
                defaultMessage="Protocol"
                description="Label for the application protocol in the Application page"
              />
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="protocol"
                value={formData.protocol}
                onChange={handleInputChange}
                required
                pattern="0\.1\.0" // # TODO: get supported versions from backend
                placeholder={intl.formatMessage({
                  id: "pages.Application.form.protocolPlaceholder",
                  defaultMessage: "Protocol",
                  description:
                    "Placeholder for the application protocol field in the Application page",
                })}
              />
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="pages.Application.form.invalidProtocolFeedback"
                  defaultMessage="The protocol must be a valid, supported version."
                  description="Feedback for invalid application protocol field in the Application page"
                />
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayName">
            <Form.Label column sm="2">
              <FormattedMessage
                id="pages.Application.displayNameLabel"
                defaultMessage="Display name"
                description="Label for the application display name in the Application page"
              />
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="displayName"
                value={formData.displayName}
                onChange={handleInputChange}
                required
                placeholder={intl.formatMessage({
                  id: "pages.Application.form.displayNamePlaceholder",
                  defaultMessage: "Display name",
                  description:
                    "Placeholder for the application display name field in the Application page",
                })}
              />
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="pages.Application.form.invalidDisplayNameFeedback"
                  defaultMessage="Please provide a valid application display name."
                  description="Feedback for invalid application display name field in the Application page"
                />
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="slug">
            <Form.Label column sm="2">
              <FormattedMessage
                id="pages.Application.slugLabel"
                defaultMessage="Slug"
                description="Label for the application slug in the Application page"
              />
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="slug"
                value={formData.slug}
                onChange={handleInputChange}
                required
                pattern="[a-z0-9]+(?:-[a-z0-9]+)*"
                placeholder={intl.formatMessage({
                  id: "pages.Application.form.slugPlaceholder",
                  defaultMessage: "Slug",
                  description:
                    "Placeholder for the application slug field in the Application page",
                })}
              />
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="pages.Application.form.invalidSlugFeedback"
                  defaultMessage="Please provide a valid application slug. It should only contain lower case ASCII letters (from a to z), digits and -, and should not start or end with a -."
                  description="Feedback for invalid application slug field in the Application page"
                />
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="description">
            <Form.Label column sm="2">
              <FormattedMessage
                id="pages.Application.descriptionLabel"
                defaultMessage="Description"
                description="Label for the application description in the Application page"
              />
            </Form.Label>
            <Col sm="10">
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder={intl.formatMessage({
                  id: "pages.Application.form.descriptionPlaceholder",
                  defaultMessage: "Description",
                  description:
                    "Placeholder for the description field in the Application page",
                })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="sourceUrl">
            <Form.Label column sm="2">
              <FormattedMessage
                id="pages.Application.sourceUrlLabel"
                defaultMessage="Source URL"
                description="Label for the application source URL in the Application page"
              />
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="sourceUrl"
                value={formData.sourceUrl}
                onChange={handleInputChange}
                required
                placeholder={intl.formatMessage({
                  id: "pages.Application.form.sourceUrlPlaceholder",
                  defaultMessage: "Source URL",
                  description:
                    "Placeholder for the source URL field in the Application page",
                })}
              />
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="pages.Application.form.invalidSourceUrlFeedback"
                  defaultMessage="Please provide a valid URL."
                  description="Feedback for invalid source URL field in the Application page"
                />
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="scope">
            <Form.Label column sm="2">
              <FormattedMessage
                id="pages.Application.scopeLabel"
                defaultMessage="Scope"
                description="Label for the application scope in the Application page"
              />
            </Form.Label>
            <Col sm="10">
              {isSupportedApplicationScope(formData.scope) ? (
                <ApplicationScopeInput
                  value={formData.scope}
                  onChange={handleInputChange}
                  required
                />
              ) : (
                <Form.Control value={formData.scope} readOnly isInvalid />
              )}
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="pages.Application.form.invalidAppScopeFeedback"
                  defaultMessage="Not a valid scope for the app."
                  description="Feedback for invalid scope field in the Application page"
                />
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Row>
            <Form.Label column sm="2">
              <FormattedMessage
                id="pages.Application.requiredInterfacesLabel"
                defaultMessage="Required Astarte interfaces"
                description="Label for the required Astarte interfaces in the Application page"
              />
            </Form.Label>
            <Col sm="10">
              <InterfacesTable
                interfaces={formData.requiredInterfaces}
                onChange={handleInterfacesChange}
              />
              {formData.requiredInterfaces.length === 0 && (
                <FormattedMessage
                  id="pages.Application.noRequiredAstarteInterface"
                  defaultMessage="No required Astarte interface. This application is always displayed."
                  description="Message shown when the application has no Astarte interface requirements"
                />
              )}
            </Col>
          </Row>
        </Stack>
      </Form>
      {showDeleteModal && (
        <DeleteModal
          confirmText={app.slug}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteApplication}
          isDeleting={isDeletingApplication}
          title={
            <FormattedMessage
              id="pages.Application.deleteModal.title"
              defaultMessage="Delete Application"
              description="Title for the confirmation modal to delete an application"
            />
          }
        >
          <p>
            <FormattedMessage
              id="pages.Application.deleteModal.description"
              defaultMessage="This action cannot be undone. This will permanently delete the application <bold>{application}</bold>."
              description="Description for the confirmation modal to delete an application"
              values={{
                application: app.displayName,
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </DeleteModal>
      )}
    </div>
  );
};

const Application = () => {
  const { applicationId = "" } = useParams();
  const [getApplicationQuery, getApplication] =
    useQueryLoader<Application_getApplication_Query>(GET_APPLICATION_QUERY);

  useEffect(
    () => getApplication({ id: applicationId }),
    [getApplication, applicationId]
  );

  return (
    <Suspense
      fallback={
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={(props) => (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <p>
              <FormattedMessage
                id="pages.Application.Application.feedback"
                defaultMessage="The page couldn't load."
                description="Feedback message on a loading error for the Application page"
              />
            </p>
            <Button onClick={props.resetErrorBoundary}>
              <FormattedMessage
                id="pages.Application.loadingError.retryButton"
                defaultMessage="Try again"
                description="Retry button on loading error for the Application page"
              />
            </Button>
          </div>
        )}
        onReset={() => getApplication({ id: applicationId })}
      >
        {getApplicationQuery && (
          <ApplicationContent getApplicationQuery={getApplicationQuery} />
        )}
      </ErrorBoundary>
    </Suspense>
  );
};

export default Application;
