import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import type { Tenants_deleteTenant_Mutation } from "api/__generated__/Tenants_deleteTenant_Mutation.graphql";
import type { Tenants_getTenants_Query } from "api/__generated__/Tenants_getTenants_Query.graphql";
import DeleteModal from "components/DeleteModal";
import Icon from "components/Icon";
import OptionsMenu from "components/OptionsMenu";
import SectionCard from "components/SectionCard";
import Spinner from "components/Spinner";
import { Link, Route } from "Navigation";
import "./Tenants.scss";

const GET_TENANTS_QUERY = graphql`
  query Tenants_getTenants_Query {
    tenants {
      id
      name
      realm {
        cluster {
          name
          provider
          zone
        }
      }
    }
  }
`;

const DELETE_TENANT_MUTATION = graphql`
  mutation Tenants_deleteTenant_Mutation($input: DeleteTenantWithRealmInput!) {
    deleteTenantWithRealm(input: $input) {
      tenant {
        id
      }
    }
  }
`;

type SelectedTenant = { id: string; name: string };

interface TenantsContentProps {
  getTenantsQuery: PreloadedQuery<Tenants_getTenants_Query>;
}

const TenantsContent = ({ getTenantsQuery }: TenantsContentProps) => {
  const tenantsData = usePreloadedQuery(GET_TENANTS_QUERY, getTenantsQuery);
  const [tenantToDelete, setTenantToDelete] = useState<SelectedTenant | null>(
    null
  );
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);

  const tenants = useMemo(
    () =>
      tenantsData.tenants.map((tenant) => ({
        ...tenant,
        realm: {
          ...tenant.realm,
          cluster: {
            ...tenant.realm.cluster,
          },
        },
      })),
    [tenantsData.tenants]
  );

  const [deleteTenant, isDeletingTenant] =
    useMutation<Tenants_deleteTenant_Mutation>(DELETE_TENANT_MUTATION);

  const handleDeleteTenant = useCallback(
    async (tenantId: string) => {
      deleteTenant({
        variables: { input: { tenantId } },
        onCompleted(data, errors) {
          setTenantToDelete(null);
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setErrorFeedback(errorFeedback);
          }
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.Tenants.deleteErrorFeedback"
              defaultMessage="Could not delete the tenant, please try again."
              description="Feedback for unknown deletion error in the Tenants page"
            />
          );
          setTenantToDelete(null);
        },
        updater(store, data) {
          const tenantId = data?.deleteTenantWithRealm?.tenant.id;
          if (tenantId) {
            const root = store.getRoot();
            const tenants = root.getLinkedRecords("tenants");
            if (tenants) {
              root.setLinkedRecords(
                tenants.filter((tenant) => tenant.getDataID() !== tenantId),
                "tenants"
              );
            }
          }
        },
      });
    },
    [deleteTenant]
  );

  return (
    <div className="py-4 px-5">
      <main>
        <Alert
          show={!!errorFeedback}
          variant="danger"
          onClose={() => setErrorFeedback(null)}
          dismissible
        >
          {errorFeedback}
        </Alert>
        {tenants.length === 0 ? (
          <div className="mt-5 d-flex justify-content-center">
            <div className="rounded shadow p-5 text-center">
              <h2>
                <FormattedMessage
                  id="pages.Tenants.noTenantsTitle"
                  defaultMessage="This space is empty"
                  description="Title for missing tenants message in Tenants page"
                />
              </h2>
              <p className="text-muted">
                <FormattedMessage
                  id="pages.Tenants.noTenantsSubtitle"
                  defaultMessage="You haven't created any tenants yet."
                  description="Subtitle for missing tenants message in Tenants page"
                />
              </p>
              <p className="mt-5">
                <Link route={Route.tenantsNew} className="btn btn-primary">
                  <FormattedMessage
                    id="pages.Tenants.noTenantsButton"
                    defaultMessage="Create new tenant"
                    description="Title for the link to create the first tenant in Tenants page"
                  />
                </Link>
              </p>
            </div>
          </div>
        ) : (
          <div className="tenants-list">
            <Link
              route={Route.tenantsNew}
              className="rounded shadow p-3 mb-4 d-flex justify-content-center align-items-center"
            >
              <Icon icon="plus" className="me-2" />
              <strong>
                <FormattedMessage
                  id="pages.Tenants.createNewTenant"
                  defaultMessage="Create new tenant"
                  description="Title for the card to create a new tenant in Tenants page"
                />
              </strong>
            </Link>
            {tenants.map((tenant) => (
              <SectionCard
                key={tenant.id}
                title={
                  <Link
                    route={Route.tenantsEdit}
                    params={{ tenantId: tenant.id }}
                  >
                    {tenant.name}
                  </Link>
                }
                menu={
                  <OptionsMenu alignEnd>
                    <OptionsMenu.Item
                      className="text-danger"
                      onClick={() =>
                        setTenantToDelete({ id: tenant.id, name: tenant.name })
                      }
                    >
                      <FormattedMessage
                        id="pages.Tenants.tenantDeleteButton"
                        defaultMessage="Delete"
                        description="Button to delete a tenant in the Tenants page"
                      />
                    </OptionsMenu.Item>
                  </OptionsMenu>
                }
              >
                <div className="mb-2">
                  <table>
                    <tbody>
                      <tr>
                        <td className="text-muted">
                          <FormattedMessage
                            id="pages.Tenants.tenantInfo.clusterLabel"
                            defaultMessage="Cluster"
                            description="Label for the cluster property of a tenant in Tenants page"
                          />
                        </td>
                        <td className="ps-2">{tenant.realm.cluster.name}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">
                          <FormattedMessage
                            id="pages.Tenants.tenantInfo.locationLabel"
                            defaultMessage="Location"
                            description="Label for the location property of a tenant in Tenants page"
                          />
                        </td>
                        <td className="ps-2">
                          {tenant.realm.cluster.provider}{" "}
                          {tenant.realm.cluster.zone}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </SectionCard>
            ))}
          </div>
        )}
        {tenantToDelete && (
          <DeleteModal
            confirmText={tenantToDelete.name}
            onCancel={() => setTenantToDelete(null)}
            onConfirm={() => handleDeleteTenant(tenantToDelete.id)}
            isDeleting={isDeletingTenant}
            title={
              <FormattedMessage
                id="pages.Tenants.deleteModal.title"
                defaultMessage="Delete Tenant"
                description="Title for the confirmation modal to delete a tenant"
              />
            }
          >
            <p>
              <FormattedMessage
                id="pages.Tenants.deleteModal.description"
                defaultMessage="This action cannot be undone. This will permanently delete the tenant <bold>{tenant}</bold>."
                description="Description for the confirmation modal to delete a tenant"
                values={{
                  tenant: tenantToDelete.name,
                  bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
                }}
              />
            </p>
          </DeleteModal>
        )}
      </main>
    </div>
  );
};

const Tenants = () => {
  const [getTenantsQuery, getTenants] =
    useQueryLoader<Tenants_getTenants_Query>(GET_TENANTS_QUERY);

  useEffect(() => getTenants({}), [getTenants]);

  return (
    <Suspense
      fallback={
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={(props) => (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <p>
              <FormattedMessage
                id="pages.Tenants.loadingError.feedback"
                defaultMessage="The page couldn't load."
                description="Feedback message on a loading error for the Tenants page"
              />
            </p>
            <Button onClick={props.resetErrorBoundary}>
              <FormattedMessage
                id="pages.Tenants.loadingError.retryButton"
                defaultMessage="Try again"
                description="Retry button on loading error for the Tenants page"
              />
            </Button>
          </div>
        )}
        onReset={() => getTenants({})}
      >
        {getTenantsQuery && (
          <TenantsContent getTenantsQuery={getTenantsQuery} />
        )}
      </ErrorBoundary>
    </Suspense>
  );
};

export default Tenants;
