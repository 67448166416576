/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ApplicationScope = "APPLIANCE" | "GLOBAL" | "%future added value";
export type Application_getApplication_QueryVariables = {
    id: string;
};
export type Application_getApplication_QueryResponse = {
    readonly application: {
        readonly protocol: string;
        readonly slug: string;
        readonly displayName: string;
        readonly description: string | null;
        readonly sourceUrl: string;
        readonly scope: ApplicationScope;
        readonly requiredInterfaces: ReadonlyArray<{
            readonly name: string;
            readonly majorVersion: number;
            readonly minorVersion: number;
        }>;
    } | null;
};
export type Application_getApplication_Query = {
    readonly response: Application_getApplication_QueryResponse;
    readonly variables: Application_getApplication_QueryVariables;
};



/*
query Application_getApplication_Query(
  $id: ID!
) {
  application(id: $id) {
    protocol
    slug
    displayName
    description
    sourceUrl
    scope
    requiredInterfaces {
      name
      majorVersion
      minorVersion
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "protocol",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scope",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "AstarteInterface",
  "kind": "LinkedField",
  "name": "requiredInterfaces",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "majorVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minorVersion",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Application_getApplication_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Application",
        "kind": "LinkedField",
        "name": "application",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Application_getApplication_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Application",
        "kind": "LinkedField",
        "name": "application",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e0b07a3739985295c59b76cb2dfe6e83",
    "id": null,
    "metadata": {},
    "name": "Application_getApplication_Query",
    "operationKind": "query",
    "text": "query Application_getApplication_Query(\n  $id: ID!\n) {\n  application(id: $id) {\n    protocol\n    slug\n    displayName\n    description\n    sourceUrl\n    scope\n    requiredInterfaces {\n      name\n      majorVersion\n      minorVersion\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '309a8f92a2f965ff700d8ba56a55568d';
export default node;
