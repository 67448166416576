import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";

import type { ApplicationCreate_createApplication_Mutation } from "api/__generated__/ApplicationCreate_createApplication_Mutation.graphql";
import ApplicationScopeInput, {
  SupportedApplicationScope,
} from "components/ApplicationScopeInput";
import InterfacesTable, { Interface } from "components/InterfacesTable";
import Spinner from "components/Spinner";
import { Route, useNavigate } from "Navigation";
import "./ApplicationCreate.scss";

const CREATE_APPLICATION_MUTATION = graphql`
  mutation ApplicationCreate_createApplication_Mutation(
    $input: CreateApplicationInput!
  ) {
    createApplication(input: $input) {
      application {
        id
      }
    }
  }
`;

const toApplicationSlug = (text: string) =>
  text
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, "")
    .replace(/-+/g, "-")
    .replace(/^-/, "")
    .replace(/-$/, "");

interface FormData {
  protocol: string;
  slug: string;
  displayName: string;
  description: string;
  sourceUrl: string;
  scope: SupportedApplicationScope;
  requiredInterfaces: Interface[];
}

const initialFormData: FormData = {
  protocol: "",
  slug: "",
  displayName: "",
  description: "",
  sourceUrl: "",
  scope: "APPLIANCE",
  requiredInterfaces: [],
};

const ApplicationCreate = () => {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [validated, setValidated] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const { tenantId = "" } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();

  const [createApplication, isCreatingApplication] =
    useMutation<ApplicationCreate_createApplication_Mutation>(
      CREATE_APPLICATION_MUTATION
    );

  const handleInputChange: React.ChangeEventHandler<
    HTMLInputElement & HTMLSelectElement
  > = useCallback((event) => {
    const target = event.target;
    const value = target.value;
    const field = target.id;
    setFormData((data) => ({ ...data, [field]: value }));
    if (field === "displayName") {
      const displayName = value;
      const slug = toApplicationSlug(displayName);
      setFormData((data) => ({ ...data, slug }));
    }
  }, []);

  const handleInterfacesChange = useCallback((interfaces: Interface[]) => {
    setFormData((data) => ({ ...data, requiredInterfaces: interfaces }));
  }, []);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        return setValidated(true);
      }
      createApplication({
        variables: { input: { tenantId, application: formData } },
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setErrorFeedback(errorFeedback);
          }
          const applicationId = data.createApplication?.application.id;
          if (applicationId) {
            navigate({
              route: Route.applicationEdit,
              params: { tenantId, applicationId },
            });
          } else {
            navigate({ route: Route.applications, params: { tenantId } });
          }
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.ApplicationCreate.creationErrorFeedback"
              defaultMessage="Could not create the application, please try again."
              description="Feedback for unknown creation error in the ApplicationCreate page"
            />
          );
        },
        updater(store, data) {
          const applicationId = data.createApplication?.application?.id;
          if (applicationId) {
            const tenant = store.get(tenantId);
            const application = store.get(applicationId);
            const applications = tenant?.getLinkedRecords("applications");
            if (tenant && applications && application) {
              tenant.setLinkedRecords(
                [...applications, application],
                "applications"
              );
            }
          }
        },
      });
    },
    [createApplication, formData, navigate, tenantId]
  );

  return (
    <div className="py-4 px-5">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Stack gap={3}>
          <header className="d-flex justify-content-between align-items-center">
            <h2 className="text-muted">
              <FormattedMessage
                id="pages.ApplicationCreate.title"
                defaultMessage="Create application"
                description="Title for the ApplicationCreate page"
              />
            </h2>
            <Button
              variant="primary"
              type="submit"
              disabled={isCreatingApplication}
            >
              {isCreatingApplication && <Spinner size="sm" className="me-2" />}
              <FormattedMessage
                id="pages.ApplicationCreate.form.createButton"
                defaultMessage="Create"
                description="Title for the button to create the application in the ApplicationCreate page"
              />
            </Button>
          </header>
          <Alert
            show={!!errorFeedback}
            variant="danger"
            onClose={() => setErrorFeedback(null)}
            dismissible
          >
            {errorFeedback}
          </Alert>
          <Form.Group as={Row} controlId="protocol">
            <Form.Label column sm="2">
              <FormattedMessage
                id="pages.ApplicationCreate.protocolLabel"
                defaultMessage="Protocol"
                description="Label for the application protocol in the ApplicationCreate page"
              />
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="protocol"
                value={formData.protocol}
                onChange={handleInputChange}
                required
                pattern="0\.1\.0" // # TODO: get supported versions from backend
                placeholder={intl.formatMessage({
                  id: "pages.ApplicationCreate.form.protocolPlaceholder",
                  defaultMessage: "Protocol",
                  description:
                    "Placeholder for the application protocol field in the ApplicationCreate page",
                })}
              />
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="pages.ApplicationCreate.form.invalidProtocolFeedback"
                  defaultMessage="The protocol must be a valid, supported version."
                  description="Feedback for invalid application protocol field in the ApplicationCreate page"
                />
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="displayName">
            <Form.Label column sm="2">
              <FormattedMessage
                id="pages.ApplicationCreate.displayNameLabel"
                defaultMessage="Display name"
                description="Label for the application display name in the ApplicationCreate page"
              />
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="displayName"
                value={formData.displayName}
                onChange={handleInputChange}
                required
                placeholder={intl.formatMessage({
                  id: "pages.ApplicationCreate.form.displayNamePlaceholder",
                  defaultMessage: "Display name",
                  description:
                    "Placeholder for the application display name field in the ApplicationCreate page",
                })}
              />
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="pages.ApplicationCreate.form.invalidDisplayNameFeedback"
                  defaultMessage="Please provide a valid application display name."
                  description="Feedback for invalid application display name field in the ApplicationCreate page"
                />
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="slug">
            <Form.Label column sm="2">
              <FormattedMessage
                id="pages.ApplicationCreate.slugLabel"
                defaultMessage="Slug"
                description="Label for the application slug in the ApplicationCreate page"
              />
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="slug"
                value={formData.slug}
                onChange={handleInputChange}
                required
                pattern="[a-z0-9]+(?:-[a-z0-9]+)*"
                placeholder={intl.formatMessage({
                  id: "pages.ApplicationCreate.form.slugPlaceholder",
                  defaultMessage: "Slug",
                  description:
                    "Placeholder for the application slug field in the ApplicationCreate page",
                })}
              />
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="pages.ApplicationCreate.form.invalidSlugFeedback"
                  defaultMessage="Please provide a valid application slug. It should only contain lower case ASCII letters (from a to z), digits and -, and should not start or end with a -."
                  description="Feedback for invalid application slug field in the ApplicationCreate page"
                />
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="description">
            <Form.Label column sm="2">
              <FormattedMessage
                id="pages.ApplicationCreate.descriptionLabel"
                defaultMessage="Description"
                description="Label for the application description in the ApplicationCreate page"
              />
            </Form.Label>
            <Col sm="10">
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder={intl.formatMessage({
                  id: "pages.ApplicationCreate.form.descriptionPlaceholder",
                  defaultMessage: "Description",
                  description:
                    "Placeholder for the description field in the ApplicationCreate page",
                })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="sourceUrl">
            <Form.Label column sm="2">
              <FormattedMessage
                id="pages.ApplicationCreate.sourceUrlLabel"
                defaultMessage="Source URL"
                description="Label for the application source URL in the ApplicationCreate page"
              />
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="sourceUrl"
                value={formData.sourceUrl}
                onChange={handleInputChange}
                required
                placeholder={intl.formatMessage({
                  id: "pages.ApplicationCreate.form.sourceUrlPlaceholder",
                  defaultMessage: "Source URL",
                  description:
                    "Placeholder for the source URL field in the ApplicationCreate page",
                })}
              />
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="pages.ApplicationCreate.form.invalidSourceUrlFeedback"
                  defaultMessage="Please provide a valid URL."
                  description="Feedback for invalid source URL field in the ApplicationCreate page"
                />
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="scope">
            <Form.Label column sm="2">
              <FormattedMessage
                id="pages.ApplicationCreate.scopeLabel"
                defaultMessage="Scope"
                description="Label for the application scope in the ApplicationCreate page"
              />
            </Form.Label>
            <Col sm="10">
              <ApplicationScopeInput
                value={formData.scope}
                onChange={handleInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="pages.ApplicationCreate.form.invalidAppScopeFeedback"
                  defaultMessage="Please provide a scope for the app."
                  description="Feedback for invalid scope field in the ApplicationCreate page"
                />
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Row>
            <Form.Label column sm="2">
              <FormattedMessage
                id="pages.ApplicationCreate.requiredInterfacesLabel"
                defaultMessage="Required Astarte interfaces"
                description="Label for the required Astarte interfaces in the ApplicationCreate page"
              />
            </Form.Label>
            <Col sm="10">
              <InterfacesTable
                interfaces={formData.requiredInterfaces}
                onChange={handleInterfacesChange}
              />
              {formData.requiredInterfaces.length === 0 && (
                <FormattedMessage
                  id="pages.ApplicationCreate.noRequiredAstarteInterface"
                  defaultMessage="No required Astarte interface. This application is always displayed."
                  description="Message shown when the application has no Astarte interface requirements"
                />
              )}
            </Col>
          </Row>
        </Stack>
      </Form>
    </div>
  );
};

export default ApplicationCreate;
