/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type Tenants_getTenants_QueryVariables = {};
export type Tenants_getTenants_QueryResponse = {
    readonly tenants: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly realm: {
            readonly cluster: {
                readonly name: string;
                readonly provider: string;
                readonly zone: string;
            };
        };
    }>;
};
export type Tenants_getTenants_Query = {
    readonly response: Tenants_getTenants_QueryResponse;
    readonly variables: Tenants_getTenants_QueryVariables;
};



/*
query Tenants_getTenants_Query {
  tenants {
    id
    name
    realm {
      cluster {
        name
        provider
        zone
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provider",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zone",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Tenants_getTenants_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenants",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Realm",
            "kind": "LinkedField",
            "name": "realm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Cluster",
                "kind": "LinkedField",
                "name": "cluster",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Tenants_getTenants_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenants",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Realm",
            "kind": "LinkedField",
            "name": "realm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Cluster",
                "kind": "LinkedField",
                "name": "cluster",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4611032d5f44b90a73373ffa2f3e83dd",
    "id": null,
    "metadata": {},
    "name": "Tenants_getTenants_Query",
    "operationKind": "query",
    "text": "query Tenants_getTenants_Query {\n  tenants {\n    id\n    name\n    realm {\n      cluster {\n        name\n        provider\n        zone\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '749a8566da50916cb4476654e83d6577';
export default node;
