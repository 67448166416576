import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import _ from "lodash";

import * as images from "assets/images";
import type { Tenant_deleteTenant_Mutation } from "api/__generated__/Tenant_deleteTenant_Mutation.graphql";
import type {
  Tenant_updateTenant_Mutation,
  DesignSystemInput,
  TenantAuthConfigInput,
} from "api/__generated__/Tenant_updateTenant_Mutation.graphql";
import type {
  Tenant_getTenant_Query,
  Tenant_getTenant_QueryResponse,
} from "api/__generated__/Tenant_getTenant_Query.graphql";
import Code from "components/Code";
import DeleteModal from "components/DeleteModal";
import Icon from "components/Icon";
import Image from "components/Image";
import Result from "components/Result";
import SectionCard from "components/SectionCard";
import Spinner from "components/Spinner";
import AstarteToken, {
  allAppEngineClaims,
  allPairingClaims,
  allRealmManagementClaims,
  allRealmApisClaims,
} from "models/AstarteToken";
import { Link, Route, useNavigate } from "Navigation";

type Tenant = NonNullable<Tenant_getTenant_QueryResponse["tenant"]>;

const DEFAULT_TOKEN_EXPIRY_SECONDS = 60 * 60 * 24;

const GET_TENANT_QUERY = graphql`
  query Tenant_getTenant_Query($id: ID!) {
    tenant(id: $id) {
      id
      name
      slug
      frontendDomain
      realm {
        name
        privateKey
        cluster {
          name
          provider
          zone
          baseApiUrl
          dashboardUrl
        }
      }
      design {
        logo
        theme
      }
      authConfig {
        recaptchaSecretKey
        recaptchaSiteKey
      }
    }
  }
`;

const DELETE_TENANT_MUTATION = graphql`
  mutation Tenant_deleteTenant_Mutation($input: DeleteTenantWithRealmInput!) {
    deleteTenantWithRealm(input: $input) {
      tenant {
        id
      }
    }
  }
`;

const UPDATE_TENANT_MUTATION = graphql`
  mutation Tenant_updateTenant_Mutation($input: UpdateTenantInput!) {
    updateTenant(input: $input) {
      tenant {
        id
        authConfig {
          recaptchaSecretKey
          recaptchaSiteKey
        }
        design {
          logo
          theme
        }
      }
    }
  }
`;

const copyContentToClipboard = (nodeSelector: string) => {
  const node = document.querySelector<HTMLInputElement>(nodeSelector);
  if (node == null) {
    return;
  }
  const selection = window.getSelection();
  if (selection == null) {
    return;
  }
  if (selection.rangeCount > 0) {
    selection.removeAllRanges();
  }
  try {
    node.focus();
    node.select();
  } catch {
    selection.selectAllChildren(node);
  }
  document.execCommand("copy");
};

type PaletteColorProps = {
  color: string;
  name: string;
};

const PaletteColor = ({ color, name }: PaletteColorProps) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <div>
        <small className="text-muted">{name}</small>
      </div>
      <div
        className="rounded shadow"
        style={{ width: "3em", height: "3em", backgroundColor: color }}
      />
    </div>
  );
};

type AssetLinkProps = {
  href?: string | File | null;
};

const AssetLink = ({ href }: AssetLinkProps) => {
  if (!href) {
    return null;
  }
  const assetUrl = href instanceof File ? URL.createObjectURL(href) : href;
  const assetName =
    href instanceof File ? href.name : assetUrl.split("/").pop();
  return (
    <a href={assetUrl} target="_blank" rel="noreferrer">
      {assetName}
    </a>
  );
};

type DesignSystem = {
  logo: string | null;
  theme: string | null;
};

const defaultDesign = { logo: images.brand, theme: "clea" } as const;

const availableThemes = [
  {
    id: "clea",
    name: "Clea",
    colors: {
      primary: "#0631cc",
      secondary: "#6c757d",
      accent: "#11b0ef",
      background: "#ffffff",
    },
  },
  {
    id: "energy",
    name: "Energy",
    colors: {
      primary: "#91ff0a",
      secondary: "#d90368",
      accent: "#91ff0a",
      background: "#ffffff",
    },
  },
  {
    id: "fall",
    name: "Fall",
    colors: {
      primary: "#622f01",
      secondary: "#d4d18e",
      accent: "#ccb277",
      background: "#ffffff",
    },
  },
  {
    id: "fashion",
    name: "Fashion",
    colors: {
      primary: "#ac7df5",
      secondary: "#ec4e20",
      accent: "#ac7df5",
      background: "#ffffff",
    },
  },
  {
    id: "night",
    name: "Night",
    colors: {
      primary: "#0F044C",
      secondary: "#787A91",
      accent: "#141E61",
      background: "#ffffff",
    },
  },
  {
    id: "noir",
    name: "Noir",
    colors: {
      primary: "#01110a",
      secondary: "#ec4e20",
      accent: "#e6d460",
      background: "#ffffff",
    },
  },
  {
    id: "spring",
    name: "Spring",
    colors: {
      primary: "#8eb1c7",
      secondary: "#eb4511",
      accent: "#b02e0c",
      background: "#ffffff",
    },
  },
  {
    id: "summer",
    name: "Summer",
    colors: {
      primary: "#E45826",
      secondary: "#4A3933",
      accent: "#F0A500",
      background: "#ffffff",
    },
  },
] as const;

const getHasCustomTheme = (design: DesignSystemInput) =>
  design.themeFile != null ||
  !!design.theme?.startsWith("http") ||
  !!design.theme?.startsWith("blob");

type DesignSystemCardProps = {
  design: DesignSystem;
  onSave: (design: DesignSystemInput) => void;
  isSaving: boolean;
};

const DesignSystemCard = ({
  design,
  isSaving,
  onSave,
}: DesignSystemCardProps) => {
  const intl = useIntl();
  const [draftDesign, setDraftDesign] = useState<DesignSystemInput>(design);
  const [showCustomTheme, setShowCustomTheme] = useState(
    getHasCustomTheme(design)
  );

  const handleInputChange: React.ChangeEventHandler<
    HTMLInputElement & HTMLSelectElement
  > = useCallback((event) => {
    const target = event.target;
    let fieldValue: string | File | null = target.value;
    if (target.type === "file" && target.files) {
      fieldValue = target.files[0];
    }
    if (target.id === "theme" && target.value === "clea") {
      fieldValue = null;
    }
    const field = target.id;
    setDraftDesign((draftDesign) => ({
      ...draftDesign,
      [field]: fieldValue,
    }));
  }, []);

  const handleResetDesign = useCallback(() => {
    setDraftDesign(design);
    setShowCustomTheme(getHasCustomTheme(design));
  }, [design]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onSave(draftDesign);
    },
    [draftDesign, onSave]
  );

  useEffect(() => {
    setDraftDesign(design);
    setShowCustomTheme(getHasCustomTheme(design));
  }, [design]);

  const logoUrl =
    draftDesign.logoFile instanceof File
      ? URL.createObjectURL(draftDesign.logoFile)
      : draftDesign.logo || defaultDesign.logo;
  const hasLogo = draftDesign.logoFile != null || draftDesign.logo != null;
  const hasCustomTheme = getHasCustomTheme(draftDesign);
  const selectedTheme =
    availableThemes.find((theme) => theme.id === draftDesign.theme) ||
    availableThemes.find((theme) => theme.id === defaultDesign.theme)!;

  const hasChangedDesign = !_.isEqual(draftDesign, design);
  const canResetForm = hasChangedDesign || showCustomTheme !== hasCustomTheme;
  const canSaveDesign = hasChangedDesign && !isSaving;

  return (
    <SectionCard
      title={
        <FormattedMessage
          id="pages.Tenant.designSystem.title"
          defaultMessage="Design System"
          description="Title for the Design System card in Tenant page"
        />
      }
    >
      <Form noValidate onSubmit={handleSubmit}>
        <Stack gap={3} className="px-1">
          <Form.Group>
            <h6 className="text-muted">
              <FormattedMessage
                id="pages.Tenant.designSystem.themeLabel"
                defaultMessage="Theme"
                description="Label for the theme field in the Tenant page"
              />
            </h6>
            <div className="mt-2">
              <Form.Check
                id="showPresetTheme"
                inline
                type="radio"
                label={
                  <FormattedMessage
                    id="pages.Tenant.designSystem.presetThemeOption"
                    defaultMessage="Preset"
                    description="Label for the preset theme radio button in the Tenant page"
                  />
                }
                checked={!showCustomTheme}
                onClick={() => {
                  setDraftDesign({ ...draftDesign, theme: null });
                  setShowCustomTheme(false);
                }}
              />
              <Form.Check
                id="showCustomTheme"
                inline
                type="radio"
                label={
                  <FormattedMessage
                    id="pages.Tenant.designSystem.customThemeOption"
                    defaultMessage="Custom"
                    description="Label for the custom theme radio button in the Tenant page"
                  />
                }
                checked={showCustomTheme}
                onClick={() => {
                  setDraftDesign({ ...draftDesign, themeFile: null });
                  setShowCustomTheme(true);
                }}
              />
            </div>
          </Form.Group>
          {!showCustomTheme && (
            <Form.Group controlId="theme">
              <Form.Select
                value={selectedTheme.id}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled>
                  {intl.formatMessage({
                    id: "pages.Tenant.designSystem.selectThemePrompt",
                    defaultMessage: "Select theme",
                    description:
                      "Prompt to select the theme in the Tenant page",
                  })}
                </option>
                {availableThemes.map((theme) => (
                  <option key={theme.id} value={theme.id}>
                    {theme.name}
                  </option>
                ))}
              </Form.Select>
              <div className="d-flex justify-content-between align-items-center flex-wrap mt-3">
                <PaletteColor
                  name="primary"
                  color={selectedTheme.colors.primary}
                />
                <PaletteColor
                  name="secondary"
                  color={selectedTheme.colors.secondary}
                />
                <PaletteColor
                  name="accent"
                  color={selectedTheme.colors.accent}
                />
                <PaletteColor
                  name="background"
                  color={selectedTheme.colors.background}
                />
              </div>
            </Form.Group>
          )}
          {showCustomTheme && (
            <Form.Group controlId="themeFile" className="mt-3">
              {hasCustomTheme && (
                <p>
                  <AssetLink
                    href={draftDesign.themeFile || draftDesign.theme}
                  />
                </p>
              )}
              <Form.Control
                type="file"
                accept=".css"
                data-browse={intl.formatMessage({
                  id: "pages.Tenant.designSystem.themeFileBrowseButton",
                  defaultMessage: "Browse",
                  description:
                    "Label for the button to upload the custom theme in the Tenant page",
                })}
                onChange={handleInputChange}
              />
            </Form.Group>
          )}
          <hr />
          <Form.Group controlId="logoFile">
            <h6 className="text-muted">
              <FormattedMessage
                id="pages.Tenant.designSystem.logoLabel"
                defaultMessage="Logo"
                description="Label for the logo field in the Tenant page"
              />
            </h6>
            <div className="d-flex justify-content-end">
              {hasLogo && (
                <Button
                  size="sm"
                  variant="secondary"
                  className="position-absolute rounded-circle me-n2"
                  onClick={() =>
                    setDraftDesign({
                      ...draftDesign,
                      logo: null,
                      logoFile: null,
                    })
                  }
                >
                  <Icon icon="close" />
                </Button>
              )}
              <Image className="mt-2 w-100" src={logoUrl} />
            </div>
            <Form.Control
              type="file"
              accept=".jpg,.jpeg,.gif,.png,.svg"
              data-browse={intl.formatMessage({
                id: "pages.Tenant.designSystem.logoBrowseButton",
                defaultMessage: "Browse",
                description:
                  "Label for the button to update the logo in the Tenant page",
              })}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Stack>
        <div className="mt-4 d-flex justify-content-end align-items-center">
          <Button
            variant="secondary"
            onClick={handleResetDesign}
            disabled={!canResetForm}
          >
            <FormattedMessage
              id="pages.Tenant.designSystem.resetButton"
              defaultMessage="Reset"
              description="Label for the button to reset the design system in the Tenant page"
            />
          </Button>
          <Button type="submit" disabled={!canSaveDesign} className="ms-2">
            {isSaving && <Spinner size="sm" className="me-2" />}
            <FormattedMessage
              id="pages.Tenant.designSystem.submitButton"
              defaultMessage="Update design"
              description="Label for the button to update the design system in the Tenant page"
            />
          </Button>
        </div>
      </Form>
    </SectionCard>
  );
};

type AuthConfig = {
  recaptchaSecretKey: string | null;
  recaptchaSiteKey: string | null;
};

type AuthConfigCardProps = {
  authConfig: AuthConfig;
  isSaving: boolean;
  onSave: (authConfig: AuthConfig) => void;
};

const AuthConfigCard = ({
  authConfig,
  isSaving,
  onSave,
}: AuthConfigCardProps) => {
  const isCaptchaEnabled = !!authConfig.recaptchaSecretKey;
  const [draftConfig, setDraftConfig] = useState<AuthConfig>(authConfig);
  const [showCaptchaSettings, setShowCaptchaSettings] =
    useState(isCaptchaEnabled);
  const formRef = useRef<HTMLFormElement>(null);

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => {
      const target = event.target;
      const fieldValue = target.value || null;
      const field = target.id;
      setDraftConfig((draftConfig) => ({
        ...draftConfig,
        [field]: fieldValue,
      }));
    }, []);

  const handleResetForm = useCallback(() => {
    setDraftConfig(authConfig);
    const isCaptchaEnabled = !!authConfig.recaptchaSecretKey;
    setShowCaptchaSettings(isCaptchaEnabled);
  }, [authConfig]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onSave(draftConfig);
    },
    [draftConfig, onSave]
  );

  useEffect(() => {
    setDraftConfig(authConfig);
  }, [authConfig]);

  const isFormChanged = !_.isEqual(draftConfig, authConfig);
  const canResetForm =
    isFormChanged || showCaptchaSettings !== isCaptchaEnabled;
  const isFormValid =
    !showCaptchaSettings ||
    (draftConfig.recaptchaSecretKey != null &&
      draftConfig.recaptchaSiteKey != null);
  const canSubmitForm = isFormChanged && isFormValid && !isSaving;

  return (
    <SectionCard
      title={
        <FormattedMessage
          id="pages.Tenant.authConfig.title"
          defaultMessage="Auth Settings"
          description="Title for the Auth Settings card in Tenant page"
        />
      }
    >
      <ListGroup variant="flush" className="m-n3">
        <ListGroup.Item className="my-2">
          <div className="d-flex justify-content-between">
            <h6 className="text-muted mb-3">
              <FormattedMessage
                id="pages.Tenant.authConfig.recaptchaLabel"
                defaultMessage="ReCAPTCHA"
                description="Label for the reCAPTCHA section in the Tenant page"
              />
            </h6>
            <Form.Check
              type="switch"
              id="enable-captcha"
              checked={showCaptchaSettings}
              onClick={() => {
                setDraftConfig({
                  ...draftConfig,
                  recaptchaSecretKey: null,
                  recaptchaSiteKey: null,
                });
                setShowCaptchaSettings(!showCaptchaSettings);
              }}
            />
          </div>
          <Form ref={formRef} noValidate onSubmit={handleSubmit}>
            {showCaptchaSettings && (
              <>
                <Form.Group as={Row} controlId="recaptchaSiteKey">
                  <Form.Label column sm="4">
                    <FormattedMessage
                      id="pages.Tenant.authConfig.recaptchaSiteKeyLabel"
                      defaultMessage="Site Key"
                      description="Label for the reCAPTCHA site key field in the Tenant page"
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={draftConfig.recaptchaSiteKey || ""}
                      onChange={handleInputChange}
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="recaptchaSecretKey">
                  <Form.Label column sm="4">
                    <FormattedMessage
                      id="pages.Tenant.authConfig.recaptchaSecretKeyLabel"
                      defaultMessage="Secret Key"
                      description="Label for the reCAPTCHA secret key field in the Tenant page"
                    />
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={draftConfig.recaptchaSecretKey || ""}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>
              </>
            )}
            <div className="mt-4 d-flex justify-content-end align-items-center">
              <Button
                variant="secondary"
                onClick={handleResetForm}
                disabled={!canResetForm}
              >
                <FormattedMessage
                  id="pages.Tenant.authConfig.resetButton"
                  defaultMessage="Reset"
                  description="Label for the button to reset the auth config in the Tenant page"
                />
              </Button>
              <Button type="submit" disabled={!canSubmitForm} className="ms-2">
                {isSaving && <Spinner size="sm" className="me-2" />}
                <FormattedMessage
                  id="pages.Tenant.authConfig.submitButton"
                  defaultMessage="Update settings"
                  description="Label for the button to update the auth config in the Tenant page"
                />
              </Button>
            </div>
          </Form>
        </ListGroup.Item>
      </ListGroup>
    </SectionCard>
  );
};

type TokenExpirationProps = {
  expiry?: Date;
};

const TokenExpiration = ({ expiry }: TokenExpirationProps) => {
  if (!expiry) {
    return (
      <FormattedMessage
        id="pages.Tenant.tokenWithoutExpiration"
        defaultMessage="Does not expire"
        description="Label for an empty token expiration in Tenant page"
      />
    );
  }
  return (
    <FormattedMessage
      id="pages.Tenant.tokenExpiration"
      defaultMessage="Expires at {date}"
      description="Label for token expiration in Tenant page"
      values={{
        date: (
          <FormattedDate
            value={expiry.toISOString()}
            year="numeric"
            month="long"
            day="numeric"
            hour="numeric"
            minute="numeric"
          />
        ),
      }}
    />
  );
};

type GeneralInfoCardProps = {
  onDeleteTenant: () => void;
  tenant: Tenant;
};

const GeneralInfoCard = ({ onDeleteTenant, tenant }: GeneralInfoCardProps) => {
  const dashboardAuthToken = useMemo(
    () =>
      new AstarteToken({
        claims: allRealmApisClaims,
        privateKey: tenant.realm.privateKey,
        expirationSeconds: DEFAULT_TOKEN_EXPIRY_SECONDS,
      }),
    [tenant.realm.privateKey]
  );

  const dashboardUrl = new URL("/auth", tenant.realm.cluster.dashboardUrl);
  dashboardUrl.searchParams.set("realm", tenant.realm.name);
  dashboardUrl.searchParams.set("authUrl", window.location.origin);
  dashboardUrl.hash = `access_token=${dashboardAuthToken}`;

  return (
    <SectionCard
      title={
        <FormattedMessage
          id="pages.Tenant.generalInfo.title"
          defaultMessage="General Info"
          description="Title for the General Info card in Tenant page"
        />
      }
    >
      <table>
        <tbody>
          <tr>
            <td>
              <strong>
                <FormattedMessage
                  id="pages.Tenant.generalInfo.nameLabel"
                  defaultMessage="Name"
                  description="Label for the name property in General Info card in Tenant page"
                />
              </strong>
            </td>
            <td className="ps-2">{tenant.name}</td>
          </tr>
          <tr>
            <td>
              <strong>
                <FormattedMessage
                  id="pages.Tenant.generalInfo.slugLabel"
                  defaultMessage="Slug"
                  description="Label for the slug property in General Info card in Tenant page"
                />
              </strong>
            </td>
            <td className="ps-2">{tenant.slug}</td>
          </tr>
          <tr>
            <td>
              <strong>
                <FormattedMessage
                  id="pages.Tenant.generalInfo.frontendDomainLabel"
                  defaultMessage="Frontend Domain"
                  description="Label for the frontend domain property in General Info card in Tenant page"
                />
              </strong>
            </td>
            <td className="ps-2">{tenant.frontendDomain}</td>
          </tr>
          <tr>
            <td>
              <strong>
                <FormattedMessage
                  id="pages.Tenant.generalInfo.clusterLabel"
                  defaultMessage="Cluster"
                  description="Label for the cluster property in General Info card in Tenant page"
                />
              </strong>
            </td>
            <td className="ps-2">{tenant.realm.cluster.name}</td>
          </tr>
          <tr>
            <td>
              <strong>
                <FormattedMessage
                  id="pages.Tenant.generalInfo.locationLabel"
                  defaultMessage="Location"
                  description="Label for the location property in General Info card in Tenant page"
                />
              </strong>
            </td>
            <td className="ps-2">
              {tenant.realm.cluster.provider} {tenant.realm.cluster.zone}
            </td>
          </tr>
          <tr>
            <td>
              <strong>
                <FormattedMessage
                  id="pages.Tenant.generalInfo.realmLabel"
                  defaultMessage="Realm"
                  description="Label for the realm property in General Info card in Tenant page"
                />
              </strong>
            </td>
            <td className="ps-2">{tenant.realm.name}</td>
          </tr>
          <tr>
            <td>
              <strong>
                <FormattedMessage
                  id="pages.Tenant.generalInfo.apiUrlLabel"
                  defaultMessage="API URL"
                  description="Label for the API URL property in General Info card in Tenant page"
                />
              </strong>
            </td>
            <td className="ps-2">{tenant.realm.cluster.baseApiUrl}</td>
          </tr>
        </tbody>
      </table>
      <div className="d-flex align-items-center mt-3">
        <Button href={dashboardUrl.toString()} target="_blank">
          <FormattedMessage
            id="pages.Tenant.generalInfo.dashboardButton"
            defaultMessage="Dashboard"
            description="Label for the Dashboard button in General Info card in Tenant page"
          />
        </Button>
        <Button variant="danger" className="ms-2" onClick={onDeleteTenant}>
          <FormattedMessage
            id="pages.Tenant.generalInfo.deleteButton"
            defaultMessage="Delete tenant"
            description="Label for the Delete button in General Info card in Tenant page"
          />
        </Button>
      </div>
    </SectionCard>
  );
};

type RealmPrivateKeyCardProps = {
  tenant: Tenant;
};

const RealmPrivateKeyCard = ({ tenant }: RealmPrivateKeyCardProps) => {
  return (
    <SectionCard
      title={
        <FormattedMessage
          id="pages.Tenant.realmPrivateKey.title"
          defaultMessage="Realm Private Key"
          description="Title for the Realm Private Key card in Tenant page"
        />
      }
    >
      <Code id="realm-private-key">{tenant.realm.privateKey}</Code>
      <div className="mt-3 d-flex justify-content-end align-items-center">
        <Button onClick={() => copyContentToClipboard("#realm-private-key")}>
          <FormattedMessage
            id="pages.Tenant.realmPrivateKey.copyButton"
            defaultMessage="Copy"
            description="Label for the copy-to-clipboard button in Realm Private Key card in Tenant page"
          />
        </Button>
      </div>
    </SectionCard>
  );
};

type UtilityCommandsCardProps = {
  tenant: Tenant;
};

const UtilityCommandsCard = ({ tenant }: UtilityCommandsCardProps) => {
  const appEngineToken = useMemo(
    () =>
      new AstarteToken({
        claims: allAppEngineClaims,
        privateKey: tenant.realm.privateKey,
        expirationSeconds: DEFAULT_TOKEN_EXPIRY_SECONDS,
      }),
    [tenant.realm.privateKey]
  );

  return (
    <SectionCard
      title={
        <FormattedMessage
          id="pages.Tenant.utilityCommands.title"
          defaultMessage="Clea Utility Commands"
          description="Title for the Utility Commands card in Tenant page"
        />
      }
    >
      <p>
        <FormattedMessage
          id="pages.Tenant.utilityCommands.codeDescription"
          defaultMessage="Show registered devices"
          description="Description of the code snippet in Utility Commands card in Tenant page"
        />
      </p>
      <Code cli id="show-registered-devices">
        {[
          `TOKEN="${appEngineToken}"`,
          `astartectl appengine devices list --token $TOKEN --realm-name "${tenant.realm.name}" --astarte-url "${tenant.realm.cluster.baseApiUrl}"`,
        ]}
      </Code>
      <p className="text-muted">
        <small>
          <TokenExpiration expiry={appEngineToken.expiry} />
        </small>
      </p>
      <div className="mt-3 d-flex justify-content-end align-items-center">
        <Button
          onClick={() => copyContentToClipboard("#show-registered-devices")}
        >
          <FormattedMessage
            id="pages.Tenant.utilityCommands.copyButton"
            defaultMessage="Copy"
            description="Label for the copy-to-clipboard button in Utility Commands card in Tenant page"
          />
        </Button>
      </div>
    </SectionCard>
  );
};

type AppEngineCardProps = {
  tenant: Tenant;
};

const AppEngineCard = ({ tenant }: AppEngineCardProps) => {
  const appEngineToken = useMemo(
    () =>
      new AstarteToken({
        claims: allAppEngineClaims,
        privateKey: tenant.realm.privateKey,
        expirationSeconds: DEFAULT_TOKEN_EXPIRY_SECONDS,
      }),
    [tenant.realm.privateKey]
  );

  return (
    <SectionCard
      title={
        <FormattedMessage
          id="pages.Tenant.appEngineToken.title"
          defaultMessage="AppEngine Token"
          description="Title for the AppEngine Token card in Tenant page"
        />
      }
    >
      <Code id="appengine-token">{appEngineToken.toString()}</Code>
      <p className="text-muted">
        <small>
          <TokenExpiration expiry={appEngineToken.expiry} />
        </small>
      </p>
      <div className="mt-3 d-flex justify-content-end align-items-center">
        <Button onClick={() => copyContentToClipboard("#appengine-token")}>
          <FormattedMessage
            id="pages.Tenant.appEngineToken.copyButton"
            defaultMessage="Copy"
            description="Label for the copy-to-clipboard button in AppEngine Token card in Tenant page"
          />
        </Button>
      </div>
    </SectionCard>
  );
};

type PairingCardProps = {
  tenant: Tenant;
};

const PairingCard = ({ tenant }: PairingCardProps) => {
  const pairingToken = useMemo(
    () =>
      new AstarteToken({
        claims: allPairingClaims,
        privateKey: tenant.realm.privateKey,
      }),
    [tenant.realm.privateKey]
  );

  return (
    <SectionCard
      title={
        <FormattedMessage
          id="pages.Tenant.pairingToken.title"
          defaultMessage="Pairing Token"
          description="Title for the Pairing Token card in Tenant page"
        />
      }
    >
      <Code id="pairing-token">{pairingToken.toString()}</Code>
      <p className="text-muted">
        <small>
          <TokenExpiration expiry={pairingToken.expiry} />
        </small>
      </p>
      <div className="mt-3 d-flex justify-content-end align-items-center">
        <Button onClick={() => copyContentToClipboard("#pairing-token")}>
          <FormattedMessage
            id="pages.Tenant.pairingToken.copyButton"
            defaultMessage="Copy"
            description="Label for the copy-to-clipboard button in Pairing Token card in Tenant page"
          />
        </Button>
      </div>
    </SectionCard>
  );
};

type RealmManagementCardProps = {
  tenant: Tenant;
};

const RealmManagementCard = ({ tenant }: RealmManagementCardProps) => {
  const realmManagementToken = useMemo(
    () =>
      new AstarteToken({
        claims: allRealmManagementClaims,
        privateKey: tenant.realm.privateKey,
        expirationSeconds: DEFAULT_TOKEN_EXPIRY_SECONDS,
      }),
    [tenant.realm.privateKey]
  );

  return (
    <SectionCard
      title={
        <FormattedMessage
          id="pages.Tenant.realmManagementToken.title"
          defaultMessage="Realm Management Token"
          description="Title for the Realm Management Token card in Tenant page"
        />
      }
    >
      <Code id="realm-management-token">{realmManagementToken.toString()}</Code>
      <p className="text-muted">
        <small>
          <TokenExpiration expiry={realmManagementToken.expiry} />
        </small>
      </p>
      <div className="mt-3 d-flex justify-content-end align-items-center">
        <Button
          onClick={() => copyContentToClipboard("#realm-management-token")}
        >
          <FormattedMessage
            id="pages.Tenant.realmManagementToken.copyButton"
            defaultMessage="Copy"
            description="Label for the copy-to-clipboard button in Realm Management Token card in Tenant page"
          />
        </Button>
      </div>
    </SectionCard>
  );
};

interface TenantContentProps {
  getTenantQuery: PreloadedQuery<Tenant_getTenant_Query>;
}

const TenantContent = ({ getTenantQuery }: TenantContentProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSavingDesignSystem, setIsSavingDesignSystem] = useState(false);
  const [isSavingAuthConfig, setIsSavingAuthConfig] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const { tenantId = "" } = useParams();
  const navigate = useNavigate();
  const tenantData = usePreloadedQuery(GET_TENANT_QUERY, getTenantQuery);

  const tenant = useMemo(
    () =>
      tenantData.tenant && {
        ...tenantData.tenant,
        realm: {
          ...tenantData.tenant.realm,
          cluster: {
            ...tenantData.tenant.realm.cluster,
          },
        },
        design: {
          ...tenantData.tenant.design,
        },
        authConfig: {
          ...tenantData.tenant.authConfig,
        },
      },
    [tenantData.tenant]
  );

  const [deleteTenant, isDeletingTenant] =
    useMutation<Tenant_deleteTenant_Mutation>(DELETE_TENANT_MUTATION);

  const [updateTenant] = useMutation<Tenant_updateTenant_Mutation>(
    UPDATE_TENANT_MUTATION
  );

  const handleDeleteTenant = useCallback(async () => {
    deleteTenant({
      variables: { input: { tenantId } },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setShowDeleteModal(false);
          return setErrorFeedback(errorFeedback);
        }
        navigate({ route: Route.tenants });
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.Tenant.deleteErrorFeedback"
            defaultMessage="Could not delete the tenant, please try again."
            description="Feedback for unknown deletion error in the Tenant page"
          />
        );
        setShowDeleteModal(false);
      },
      updater(store, data) {
        const tenantId = data?.deleteTenantWithRealm?.tenant.id;
        if (tenantId) {
          const root = store.getRoot();
          const tenants = root.getLinkedRecords("tenants");
          if (tenants) {
            root.setLinkedRecords(
              tenants.filter((tenant) => tenant.getDataID() !== tenantId),
              "tenants"
            );
          }
        }
      },
    });
  }, [deleteTenant, navigate, tenantId]);

  const handleSaveDesignSystem = useCallback(
    async (design: DesignSystemInput) => {
      setIsSavingDesignSystem(true);
      const variables = { input: { tenantId, design } };
      updateTenant({
        variables,
        onCompleted(data, errors) {
          setIsSavingDesignSystem(false);
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            setErrorFeedback(errorFeedback);
          }
        },
        onError(error) {
          setIsSavingDesignSystem(false);
          setErrorFeedback(
            <FormattedMessage
              id="pages.Tenant.loadingErrorFeedback"
              defaultMessage="Could not update the tenant's design, please try again."
              description="Feedback for unknown loading error in the Tenant page"
            />
          );
        },
      });
    },
    [updateTenant, tenantId]
  );

  const handleSaveAuthConfig = useCallback(
    async (authConfig: TenantAuthConfigInput) => {
      setIsSavingAuthConfig(true);
      const variables = { input: { tenantId, authConfig } };
      updateTenant({
        variables,
        onCompleted(data, errors) {
          setIsSavingAuthConfig(false);
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            setErrorFeedback(errorFeedback);
          }
        },
        onError(error) {
          setIsSavingAuthConfig(false);
          setErrorFeedback(
            <FormattedMessage
              id="pages.Tenant.saveAuthConfigErrorFeedback"
              defaultMessage="Could not update the tenant's auth config, please try again."
              description="Feedback for unknown error while saving auth config in the Tenant page"
            />
          );
        },
      });
    },
    [updateTenant, tenantId]
  );

  if (!tenant) {
    return (
      <Result.NotFound
        title={
          <FormattedMessage
            id="pages.tenant.tenantNotFound.title"
            defaultMessage="Tenant not found."
            description="Page title for a tenant not found"
          />
        }
      >
        <Link route={Route.tenants}>
          <FormattedMessage
            id="pages.tenant.tenantNotFound.message"
            defaultMessage="Return to the tenant list."
            description="Page message for a tenant not found"
          />
        </Link>
      </Result.NotFound>
    );
  }

  return (
    <div className="py-4 px-5">
      <header className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="text-muted">
          <FormattedMessage
            id="pages.Tenant.title"
            defaultMessage="Tenant Information"
            description="Title for the Tenant page"
          />
        </h2>
      </header>
      <main>
        <Alert
          show={!!errorFeedback}
          variant="danger"
          onClose={() => setErrorFeedback(null)}
          dismissible
        >
          {errorFeedback}
        </Alert>
        <Row>
          <Col lg={6}>
            <GeneralInfoCard
              tenant={tenant}
              onDeleteTenant={() => setShowDeleteModal(true)}
            />
            <DesignSystemCard
              design={tenant.design}
              isSaving={isSavingDesignSystem}
              onSave={handleSaveDesignSystem}
            />
            <AuthConfigCard
              authConfig={tenant.authConfig}
              isSaving={isSavingAuthConfig}
              onSave={handleSaveAuthConfig}
            />
          </Col>
          <Col lg={6}>
            <RealmPrivateKeyCard tenant={tenant} />
            <UtilityCommandsCard tenant={tenant} />
            <AppEngineCard tenant={tenant} />
            <PairingCard tenant={tenant} />
            <RealmManagementCard tenant={tenant} />
          </Col>
        </Row>
      </main>
      {showDeleteModal && (
        <DeleteModal
          confirmText={tenant.name}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteTenant}
          isDeleting={isDeletingTenant}
          title={
            <FormattedMessage
              id="pages.Tenant.deleteModal.title"
              defaultMessage="Delete Tenant"
              description="Title for the confirmation modal to delete a tenant"
            />
          }
        >
          <p>
            <FormattedMessage
              id="pages.Tenant.deleteModal.description"
              defaultMessage="This action cannot be undone. This will permanently delete the tenant <bold>{tenant}</bold>."
              description="Description for the confirmation modal to delete a tenant"
              values={{
                tenant: tenant.name,
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </DeleteModal>
      )}
    </div>
  );
};

const TenantPage = () => {
  const { tenantId = "" } = useParams();
  const [getTenantQuery, getTenant] =
    useQueryLoader<Tenant_getTenant_Query>(GET_TENANT_QUERY);

  useEffect(() => getTenant({ id: tenantId }), [getTenant, tenantId]);

  return (
    <Suspense
      fallback={
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={(props) => (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <p>
              <FormattedMessage
                id="pages.Tenant.loadingError.feedback"
                defaultMessage="The page couldn't load."
                description="Feedback message on a loading error for the Tenant page"
              />
            </p>
            <Button onClick={props.resetErrorBoundary}>
              <FormattedMessage
                id="pages.Tenant.loadingError.retryButton"
                defaultMessage="Try again"
                description="Retry button on loading error for the Tenant page"
              />
            </Button>
          </div>
        )}
        onReset={() => getTenant({ id: tenantId })}
      >
        {getTenantQuery && <TenantContent getTenantQuery={getTenantQuery} />}
      </ErrorBoundary>
    </Suspense>
  );
};

export default TenantPage;
