import RSA from "jsrsasign";

type ChannelsAction = "JOIN" | "WATCH" | ".*";
type HTTPAction = "DELETE" | "GET" | "PATCH" | "POST" | "PUT" | ".*";
type HTTPClaim = `${HTTPAction}::${string}`;
type ChannelsClaim = `${ChannelsAction}::${string}`;
type Claims = {
  a_aea?: HTTPClaim[];
  a_ch?: ChannelsClaim[];
  a_f?: HTTPClaim[];
  a_ha?: HTTPClaim[];
  a_pa?: HTTPClaim[];
  a_rma?: HTTPClaim[];
};

type TokenParams = {
  claims: Claims;
  expirationSeconds?: number;
  privateKey: string;
};

class AstarteToken {
  private _claims: Claims;
  private _expiry?: Date;
  private _issuedAt: Date;
  private _jwt: string;

  constructor({ claims, expirationSeconds, privateKey }: TokenParams) {
    const now = Date.now();
    const nowTimestamp = Math.trunc(now / 1000);
    let expiryTimestamp: number | undefined;
    if (expirationSeconds) {
      expiryTimestamp = nowTimestamp + expirationSeconds;
      this._expiry = new Date(expiryTimestamp * 1000);
    }
    this._issuedAt = new Date(now);
    this._claims = claims;
    try {
      const alg = "ES256";
      const header = { alg, typ: "JWT" };
      const payload = {
        ...claims,
        iss: "Clea Cloud",
        iat: nowTimestamp,
        exp: expiryTimestamp,
      };
      this._jwt = RSA.KJUR.jws.JWS.sign(
        alg,
        JSON.stringify(header),
        JSON.stringify(payload),
        privateKey
      );
    } catch {
      this._jwt = "";
    }
  }

  get expiry() {
    return this._expiry;
  }

  toString() {
    return this._jwt;
  }
}

const allHTTPClaims: HTTPClaim[] = [".*::.*"];
const allChannelsClaims: Claims = { a_ch: ["WATCH::.*", "JOIN::.*"] };
const allHousekeepingClaims: Claims = { a_ha: allHTTPClaims };
const allAppEngineClaims: Claims = { a_aea: allHTTPClaims };
const allFlowClaims: Claims = { a_f: allHTTPClaims };
const allPairingClaims: Claims = { a_pa: allHTTPClaims };
const allRealmManagementClaims: Claims = { a_rma: allHTTPClaims };
const allRealmApisClaims: Claims = {
  ...allChannelsClaims,
  ...allAppEngineClaims,
  ...allFlowClaims,
  ...allPairingClaims,
  ...allRealmManagementClaims,
};

export default AstarteToken;

export {
  allHTTPClaims,
  allChannelsClaims,
  allHousekeepingClaims,
  allAppEngineClaims,
  allFlowClaims,
  allPairingClaims,
  allRealmManagementClaims,
  allRealmApisClaims,
};
