/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type Topbar_getViewer_QueryVariables = {};
export type Topbar_getViewer_QueryResponse = {
    readonly viewer: {
        readonly name: string;
    } | null;
};
export type Topbar_getViewer_Query = {
    readonly response: Topbar_getViewer_QueryResponse;
    readonly variables: Topbar_getViewer_QueryVariables;
};



/*
query Topbar_getViewer_Query {
  viewer {
    name
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Topbar_getViewer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Topbar_getViewer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e1efe76110b224cc698602b0d5a8d26b",
    "id": null,
    "metadata": {},
    "name": "Topbar_getViewer_Query",
    "operationKind": "query",
    "text": "query Topbar_getViewer_Query {\n  viewer {\n    name\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '65841125d2c2dc56ab8906f9602b206f';
export default node;
