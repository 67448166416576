import React from "react";

type SectionCardProps = {
  children: React.ReactNode;
  menu?: React.ReactNode;
  title: React.ReactNode;
};

const SectionCard = ({ children, menu, title }: SectionCardProps) => {
  return (
    <section className="shadow rounded mb-4">
      <header className="d-flex justify-content-between align-items-center p-1">
        <strong className="p-2">{title}</strong>
        {menu}
      </header>
      <hr className="border border-primary m-0" />
      <main className="p-3">{children}</main>
    </section>
  );
};

export default SectionCard;
