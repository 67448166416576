import { useMemo } from "react";
import { useMatch } from "react-router";

type TenantContextValue = {
  tenantId: string | null;
};

const useTenant = (): TenantContextValue => {
  const routeMatch = useMatch("/tenants/:tenantId/*");
  const tenantId =
    routeMatch?.pathname === "/tenants/new"
      ? null
      : routeMatch?.params?.tenantId || null;

  const contextValue = useMemo(
    () => ({
      tenantId,
    }),
    [tenantId]
  );

  return contextValue;
};

export { useTenant };
