import React from "react";
import BoostrapDropdown from "react-bootstrap/Dropdown";

import "./Dropdown.scss";

interface Props {
  alignEnd?: boolean;
  children?: React.ReactNode;
  className?: string;
  toggle: React.ReactNode;
}

const Dropdown = ({ alignEnd, children, className = "", toggle }: Props) => (
  <BoostrapDropdown
    align={alignEnd ? "end" : "start"}
    className={`Dropdown ${className}`}
  >
    <BoostrapDropdown.Toggle as="div">{toggle}</BoostrapDropdown.Toggle>
    <BoostrapDropdown.Menu className="shadow border-end-0 border-bottom-0 border-start-0 border-primary rounded-0">
      {children}
    </BoostrapDropdown.Menu>
  </BoostrapDropdown>
);

Dropdown.Divider = BoostrapDropdown.Divider;
Dropdown.Item = BoostrapDropdown.Item;

export default Dropdown;
