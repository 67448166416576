import React, { useMemo } from "react";
import { Navigate, useRoutes } from "react-router-dom";

import { useAuth } from "contexts/Auth";
import { Route } from "Navigation";
import Sidebar from "components/Sidebar";
import Topbar from "components/Topbar";

import Application from "pages/Application";
import ApplicationCreate from "pages/ApplicationCreate";
import Applications from "pages/Applications";
import Login from "pages/Login";
import Logout from "pages/Logout";
import Register from "pages/Register";
import Profile from "pages/Profile";
import Tenant from "pages/Tenant";
import TenantCreate from "pages/TenantCreate";
import Tenants from "pages/Tenants";
import Users from "pages/Users";

function App() {
  const user = useAuth();

  const AppRoutes = useMemo(
    () =>
      user.isAuthenticated
        ? [
            { path: Route.tenants, element: <Tenants /> },
            { path: Route.tenantsNew, element: <TenantCreate /> },
            { path: Route.tenantsEdit, element: <Tenant /> },
            { path: Route.applications, element: <Applications /> },
            { path: Route.applicationsNew, element: <ApplicationCreate /> },
            { path: Route.applicationEdit, element: <Application /> },
            { path: Route.users, element: <Users /> },
            { path: Route.profile, element: <Profile /> },
            { path: Route.logout, element: <Logout /> },
            { path: "*", element: <Navigate to={Route.tenants} /> },
          ]
        : [
            { path: Route.login, element: <Login /> },
            { path: Route.register, element: <Register /> },
            { path: "*", element: <Navigate to={Route.login} /> },
          ],
    [user.isAuthenticated]
  );
  const RouterElement = useRoutes(AppRoutes);

  return (
    <div className="d-flex vh-100 flex-column">
      {user.isAuthenticated && (
        <header className="flex-grow-0">
          <Topbar />
        </header>
      )}
      <main className="vh-100 flex-grow-1 d-flex  overflow-hidden">
        {user.isAuthenticated && (
          <aside className="flex-grow-0 flex-shrink-0 overflow-auto">
            <Sidebar />
          </aside>
        )}
        <section className="flex-grow-1 overflow-auto">{RouterElement}</section>
      </main>
    </div>
  );
}

export default App;
