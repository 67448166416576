import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ErrorBoundary } from "react-error-boundary";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";

import type { TenantCreate_createTenant_Mutation } from "api/__generated__/TenantCreate_createTenant_Mutation.graphql";
import type { TenantCreate_getClusters_Query } from "api/__generated__/TenantCreate_getClusters_Query.graphql";
import Icon from "components/Icon";
import Spinner from "components/Spinner";
import { Link, Route, useNavigate } from "Navigation";
import "./TenantCreate.scss";

const GET_CLUSTERS_QUERY = graphql`
  query TenantCreate_getClusters_Query {
    clusters {
      id
      name
      provider
      zone
    }
  }
`;

const CREATE_TENANT_MUTATION = graphql`
  mutation TenantCreate_createTenant_Mutation(
    $input: CreateTenantWithNewRealmInput!
  ) {
    createTenantWithNewRealm(input: $input) {
      tenant {
        id
      }
    }
  }
`;

const toTenantSlug = (text: string) =>
  text
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, "");
const toRealmName = (text: string) =>
  text.toLowerCase().replace(/[^a-z0-9]/g, "");

interface FormData {
  tenantName: string;
  tenantSlug: string;
  frontendDomain: string;
  realmName: string;
  clusterId: string;
}

const initialFormData: FormData = {
  tenantName: "",
  tenantSlug: "",
  frontendDomain: "",
  realmName: "",
  clusterId: "",
};
interface TenantCreateContentProps {
  getClustersQuery: PreloadedQuery<TenantCreate_getClusters_Query>;
}

const TenantCreateContent = ({
  getClustersQuery,
}: TenantCreateContentProps) => {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [validated, setValidated] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const intl = useIntl();
  const navigate = useNavigate();
  const clustersData = usePreloadedQuery(GET_CLUSTERS_QUERY, getClustersQuery);

  const clusters = useMemo(
    () =>
      clustersData.clusters.map((cluster) => ({
        ...cluster,
      })),
    [clustersData.clusters]
  );

  const [createTenant, isCreatingTenant] =
    useMutation<TenantCreate_createTenant_Mutation>(CREATE_TENANT_MUTATION);

  const handleInputChange: React.ChangeEventHandler<
    HTMLInputElement & HTMLSelectElement
  > = useCallback((event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const field = target.id;
    setFormData((data) => ({ ...data, [field]: value }));
    if (field === "tenantName") {
      const tenantName = value as string;
      const tenantSlug = toTenantSlug(tenantName);
      const realmName = toRealmName(tenantName);
      setFormData((data) => ({ ...data, tenantSlug, realmName }));
    }
  }, []);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        return setValidated(true);
      }
      const tenant = {
        name: formData.tenantName,
        slug: formData.tenantSlug,
        frontendDomain: formData.frontendDomain,
      };
      const realm = {
        clusterId: formData.clusterId,
        name: formData.realmName,
      };
      createTenant({
        variables: { input: { tenant, realm } },
        onCompleted(data, errors) {
          if (errors) {
            const errorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setErrorFeedback(errorFeedback);
          }
          const tenantId = data.createTenantWithNewRealm?.tenant.id;
          if (tenantId) {
            navigate({ route: Route.tenantsEdit, params: { tenantId } });
          } else {
            navigate({ route: Route.tenants });
          }
        },
        onError(error) {
          setErrorFeedback(
            <FormattedMessage
              id="pages.TenantCreate.creationErrorFeedback"
              defaultMessage="Could not create the tenant, please try again."
              description="Feedback for unknown creation error in the TenantCreate page"
            />
          );
        },
        updater(store, data) {
          const tenantId = data.createTenantWithNewRealm?.tenant?.id;
          if (tenantId) {
            const tenant = store.get(tenantId);
            const root = store.getRoot();
            const tenants = root.getLinkedRecords("tenants");
            if (tenant && tenants) {
              root.setLinkedRecords([tenant, ...tenants], "tenants");
            }
          }
        },
      });
    },
    [createTenant, formData, navigate]
  );

  return (
    <div className="py-4 px-5 d-flex flex-column align-items-center justify-content-center">
      <header className="w-100">
        <Link
          route={Route.tenants}
          className="d-flex align-items-baseline text-muted"
        >
          <Icon icon="caretLeft" />
          <h4 className="ms-2">
            <FormattedMessage
              id="pages.TenantCreate.backButton"
              defaultMessage="Back"
              description="Title for Back button in TenantCreate page"
            />
          </h4>
        </Link>
      </header>
      <main>
        <Card className="shadow border-0 py-4 px-5 tenant-create-card">
          <Card.Body>
            <header className="text-center mb-4">
              <h2>
                <FormattedMessage
                  id="pages.TenantCreate.title"
                  defaultMessage="Create tenant"
                  description="Title for the TenantCreate page"
                />
              </h2>
            </header>
            <Alert
              show={!!errorFeedback}
              variant="danger"
              onClose={() => setErrorFeedback(null)}
              dismissible
            >
              {errorFeedback}
            </Alert>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Stack gap={3}>
                <Form.Group controlId="tenantName">
                  <Form.Control
                    value={formData.tenantName}
                    onChange={handleInputChange}
                    required
                    placeholder={intl.formatMessage({
                      id: "pages.TenantCreate.form.tenantNamePlaceholder",
                      defaultMessage: "Tenant name",
                      description:
                        "Placeholder for the tenant name field in the TenantCreate page",
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    <FormattedMessage
                      id="pages.TenantCreate.form.invalidTenantNameFeedback"
                      defaultMessage="Please provide a tenant name."
                      description="Feedback for invalid tenant name field in the TenantCreate page"
                    />
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="tenantSlug">
                  <Form.Control
                    value={formData.tenantSlug}
                    onChange={handleInputChange}
                    required
                    pattern="[a-z\d\-]+"
                    placeholder={intl.formatMessage({
                      id: "pages.TenantCreate.form.tenantSlugPlaceholder",
                      defaultMessage: "Tenant slug",
                      description:
                        "Placeholder for the tenant slug field in the TenantCreate page",
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    <FormattedMessage
                      id="pages.TenantCreate.form.invalidTenantSlugFeedback"
                      defaultMessage="Please provide a valid tenant slug."
                      description="Feedback for invalid tenant slug field in the TenantCreate page"
                    />
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="frontendDomain">
                  <Form.Control
                    value={formData.frontendDomain}
                    onChange={handleInputChange}
                    required
                    placeholder={intl.formatMessage({
                      id: "pages.TenantCreate.form.frontendDomainPlaceholder",
                      defaultMessage: "Frontend domain",
                      description:
                        "Placeholder for the frontend domain field in the TenantCreate page",
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    <FormattedMessage
                      id="pages.TenantCreate.form.invalidFrontendDomainFeedback"
                      defaultMessage="Please provide a valid frontend domain."
                      description="Feedback for invalid frontend domain field in the TenantCreate page"
                    />
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="realmName">
                  <Form.Control
                    value={formData.realmName}
                    onChange={handleInputChange}
                    required
                    pattern="[a-z][a-z0-9]{0,47}"
                    placeholder={intl.formatMessage({
                      id: "pages.TenantCreate.form.realmNamePlaceholder",
                      defaultMessage: "Realm name",
                      description:
                        "Placeholder for the realm name field in the TenantCreate page",
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    <FormattedMessage
                      id="pages.TenantCreate.form.invalidRealmNameFeedback"
                      defaultMessage="Please provide a valid realm name."
                      description="Feedback for invalid realm name field in the TenantCreate page"
                    />
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="clusterId">
                  <Form.Select
                    value={formData.clusterId}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      {intl.formatMessage({
                        id: "pages.TenantCreate.from.selectTenantLocationPrompt",
                        defaultMessage: "Location",
                        description:
                          "Prompt to select the tenant location in the TenantCreate page",
                      })}
                    </option>
                    {clusters.map((cluster) => (
                      <option key={cluster.id} value={cluster.id}>
                        {cluster.name} @ {cluster.provider} {cluster.zone}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    <FormattedMessage
                      id="pages.TenantCreate.form.invalidTenantLocationFeedback"
                      defaultMessage="Please provide a tenant location."
                      description="Feedback for invalid tenant location field in the TenantCreate page"
                    />
                  </Form.Control.Feedback>
                </Form.Group>
              </Stack>
              <Button
                variant="primary"
                type="submit"
                className="w-100 mt-4"
                disabled={isCreatingTenant}
              >
                {isCreatingTenant && <Spinner size="sm" className="me-2" />}
                <FormattedMessage
                  id="pages.TenantCreate.form.createButton"
                  defaultMessage="Create"
                  description="Title for the button to create the tenant in the TenantCreate page"
                />
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </main>
    </div>
  );
};

const TenantCreate = () => {
  const [getClustersQuery, getClusters] =
    useQueryLoader<TenantCreate_getClusters_Query>(GET_CLUSTERS_QUERY);

  useEffect(() => getClusters({}), [getClusters]);

  return (
    <Suspense
      fallback={
        <div className="h-100 d-flex flex-column justify-content-center align-items-center">
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={(props) => (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <p>
              <FormattedMessage
                id="pages.TenantCreate.loadingError.feedback"
                defaultMessage="The page couldn't load."
                description="Feedback message on a loading error for the TenantCreate page"
              />
            </p>
            <Button onClick={props.resetErrorBoundary}>
              <FormattedMessage
                id="pages.TenantCreate.loadingError.retryButton"
                defaultMessage="Try again"
                description="Retry button on loading error for the TenantCreate page"
              />
            </Button>
          </div>
        )}
        onReset={() => getClusters({})}
      >
        {getClustersQuery && (
          <TenantCreateContent getClustersQuery={getClustersQuery} />
        )}
      </ErrorBoundary>
    </Suspense>
  );
};

export default TenantCreate;
