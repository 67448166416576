/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type Register_getTermsAndConditions_QueryVariables = {};
export type Register_getTermsAndConditions_QueryResponse = {
    readonly latestTermsAndConditions: {
        readonly id: string;
        readonly text: string;
    };
};
export type Register_getTermsAndConditions_Query = {
    readonly response: Register_getTermsAndConditions_QueryResponse;
    readonly variables: Register_getTermsAndConditions_QueryVariables;
};



/*
query Register_getTermsAndConditions_Query {
  latestTermsAndConditions {
    id
    text
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TermsAndConditions",
    "kind": "LinkedField",
    "name": "latestTermsAndConditions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Register_getTermsAndConditions_Query",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Register_getTermsAndConditions_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "e86a5b02472d74de213723925191bcc3",
    "id": null,
    "metadata": {},
    "name": "Register_getTermsAndConditions_Query",
    "operationKind": "query",
    "text": "query Register_getTermsAndConditions_Query {\n  latestTermsAndConditions {\n    id\n    text\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e8e83e61c9f13f73a3cd496b29f53b27';
export default node;
