import { useCallback } from "react";
import { generatePath as routerGeneratePath, matchPath } from "react-router";
import { useNavigate as useRouterNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import type { LinkProps as RouterLinkProps } from "react-router-dom";

enum Route {
  tenants = "/tenants",
  tenantsNew = "/tenants/new",
  tenantsEdit = "/tenants/:tenantId/edit",
  users = "/tenants/:tenantId/users",
  applications = "/tenants/:tenantId/applications",
  applicationsNew = "/tenants/:tenantId/applications/new",
  applicationEdit = "/tenants/:tenantId/applications/:applicationId/edit",
  profile = "/profile",
  login = "/login",
  logout = "/logout",
  register = "/register",
}

const matchPaths = (routes: Route | Route[], path: string) => {
  const r = Array.isArray(routes) ? routes : [routes];
  return r.some((route: Route) => matchPath(route, path) != null);
};

type ParametricRoute =
  | { route: Route.tenants }
  | { route: Route.tenantsNew }
  | { route: Route.tenantsEdit; params: { tenantId: string } }
  | { route: Route.applications; params: { tenantId: string } }
  | { route: Route.applicationsNew; params: { tenantId: string } }
  | {
      route: Route.applicationEdit;
      params: { tenantId: string; applicationId: string };
    }
  | { route: Route.users; params: { tenantId: string } }
  | { route: Route.profile }
  | { route: Route.login }
  | { route: Route.logout }
  | { route: Route.register };

type LinkProps = Omit<RouterLinkProps, "to"> & ParametricRoute;

const generatePath = (route: ParametricRoute): string => {
  if ("params" in route && route.params) {
    return routerGeneratePath(route.route, route.params);
  }
  return route.route;
};

const Link = (props: LinkProps) => {
  let to, forwardProps;
  if ("params" in props) {
    const { route, params, ...rest } = props;
    to = routerGeneratePath(route, params);
    forwardProps = rest;
  } else {
    const { route, ...rest } = props;
    to = route;
    forwardProps = rest;
  }

  return <RouterLink to={to} {...forwardProps} />;
};

const useNavigate = () => {
  const routerNavigate = useRouterNavigate();
  const navigate = useCallback(
    (route: ParametricRoute | number) =>
      typeof route === "number"
        ? routerNavigate(route)
        : routerNavigate(generatePath(route)),
    [routerNavigate]
  );
  return navigate;
};

export { Link, Route, matchPaths, useNavigate };
export type { LinkProps, ParametricRoute };
