import Card from "react-bootstrap/Card";

import * as images from "assets/images";
import "./AuthPage.scss";

type AuthPageProps = {
  children: JSX.Element | JSX.Element[];
};

const AuthPage = ({ children }: AuthPageProps) => {
  return (
    <div className="p-3 vh-100 d-flex justify-content-center align-items-center">
      <Card className="shadow p-3">
        <Card.Img src={images.brand} className="p-3 auth-brand-image" />
        <Card.Body>{children}</Card.Body>
      </Card>
    </div>
  );
};

export default AuthPage;
