/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteTenantWithRealmInput = {
    tenantId: string;
};
export type Tenant_deleteTenant_MutationVariables = {
    input: DeleteTenantWithRealmInput;
};
export type Tenant_deleteTenant_MutationResponse = {
    readonly deleteTenantWithRealm: {
        readonly tenant: {
            readonly id: string;
        };
    } | null;
};
export type Tenant_deleteTenant_Mutation = {
    readonly response: Tenant_deleteTenant_MutationResponse;
    readonly variables: Tenant_deleteTenant_MutationVariables;
};



/*
mutation Tenant_deleteTenant_Mutation(
  $input: DeleteTenantWithRealmInput!
) {
  deleteTenantWithRealm(input: $input) {
    tenant {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteTenantWithRealmPayload",
    "kind": "LinkedField",
    "name": "deleteTenantWithRealm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Tenant_deleteTenant_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Tenant_deleteTenant_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e7159fad4aeac6cbc7a067962417fa03",
    "id": null,
    "metadata": {},
    "name": "Tenant_deleteTenant_Mutation",
    "operationKind": "mutation",
    "text": "mutation Tenant_deleteTenant_Mutation(\n  $input: DeleteTenantWithRealmInput!\n) {\n  deleteTenantWithRealm(input: $input) {\n    tenant {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b2c23d0c90d4e343a45774fe1552003a';
export default node;
